import React from 'react'
import LandingPageCards from '../component/LandingPageCards';
import LandingpageData from '../component/LandingPageCardData';
import backgroundlanding from '../images/backgroundlanding.png';

const Featurelanding = () => {
  return (
    <div className=' h-full px-3 bg-white-200 md:mx-auto  md:py-5' style={{backgroundImage:  `url(${backgroundlanding})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
    <h1 className='text-center text-violet-950 text-2xl lg:text-[50px]  lg:font-bold mt-5 md:pb-5'>Features</h1>

     <div className='flex flex-wrap mt-5 justify-center'>
        {LandingpageData.map((item, index) => (
            <div key={index} className='flex justify-center items-center w-full sm:w-1/2  lg:w-[360px] p-2 '>
                <div className='w-full lg:w-[360px]  h-full   bg-white border rounded shadow px-4 py-3 lg:py-5 md:me-3'>
                    <LandingPageCards image={item.img} para1={item.para1} para2={item.para2} />
                </div>
            </div>
        ))}
    </div>
</div>
  )
}

export default Featurelanding