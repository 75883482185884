// Billing.js
import React from 'react';
import { billingtable } from '../component/Inputfielddata';
import Navbar from '../component/Navbar';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';

const Billing = () => {
  return (
    <AuthenticatedLayout>
      <Navbar heading="Billing" showbuton={true} />
      <div  className='w-[300px] md:w-[605px] mx-auto flex items-center rounded  bg-white mt-4 px-2'>
        <div className='  overflow-x-auto'>
          <h1 className='text-lg font-semibold pt-3 text-purple-500 mb-2 '>Billing Information</h1>
         <div>
         <table style={{width:"100%"}} className="  text-sm font-light">
            <thead className='text-blue-950 text-sm font-semibold border-b dark:border-neutral-500 whitespace-nowrap pb-2'>
              <tr> 
                <td className="text-sm font-semibold py-2 px-3">PACKAGE</td>
                <td className="py-2 font-semibold px-3">PAYMENT ID</td>
                <td className="py-2 font-semibold px-3">PURCHASE DATE</td>
                <td className="py-2 font-semibold px-3">EXPIRY DATE</td>
                <td className="py-2 font-semibold px-3">AMOUNT PAID</td>
              </tr>
            </thead>
            <tbody>
              {billingtable.map((item, index) => (
                <tr key={index} className='text-sm font-normal'>
                  <td className="border-b px-3 py-2 text-[#072370]">{item.pack}</td>
                  <td className="border-b px-3 py-2 text-[#072370]">{item.paymentid}</td>
                  <td className="border-b px-3 py-2 text-[#072370]">{item.purDate}</td>
                  <td className="border-b px-3 py-2 text-[#072370]">{item.expDate}</td>
                  <td className="border-b px-3 py-2 text-[#072370]">{item.amountPaid}</td>
                </tr>
              ))}
            </tbody>
          </table>
         </div>
          
        </div>
      </div>



    </AuthenticatedLayout>
  );
};

export default Billing;
