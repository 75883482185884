import React from 'react'

const Stats = ({ title, icon, shareQuantity, percentage }) => {
  const customStyle = {
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.42px',
  };
  return (
    <>
      <div className="container  ">
      <div className="lg:h-[120px] w-full flex justify-center  p-3  mb-2 shadow-lg rounded-4"  >
        
        <div className="card-body text-muted" style={customStyle}>
            <span className='d-flex justify-between p-0 m-0 py-2'> 
            <h4 className="card-title  text-xs font-extrabold lg:text-base" style={{ whiteSpace: 'nowrap'}}>{title}</h4>
            <img className="w-3 h-3 lg:w-4 lg:h-4" src={icon} alt='img'/>
            </span>
            <span className='d-flex justify-between'>
             <span className="card-text me-5 text-purple-500 text-xs lg:text-2xl font-extrabold">{shareQuantity}</span>
             <span className="card-text text-danger text-xs lg:text-base" >{percentage}</span>
            </span>
        </div>
      </div>
    </div>
   
    </>
  )
}

export default Stats
