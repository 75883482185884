import instaLogo from '../images/insta.png';
import facebookLogo from '../images/facebook.png';
import twitterLogo from '../images/TwitterNew.svg';
import tiktokLogo from '../images/tiktok.png';
import linkdeinLogo from '../images/linkedin.png';
import pinterstLogo from '../images/pinterest.png';
import profileIcon1 from '../images/Group.png';
import profileIcon2 from '../images/Group (1).png';
import profileIcon3 from '../images/Group (2).png';
import profileIcon4 from '../images/Group (3).png';
import postall1 from '../images/ab-testing.png';
import postall2 from '../images/image-area.png';
import postall3 from '../images/video-box.png';
import postall4 from '../images/multimedia.png';


const data = [
    {
        id:1,
        icon:instaLogo,
        heading:"11.2k",
        subheading:"Instagram",
    },
    {
        id:2,
        icon:facebookLogo,
        heading:"12.2k",
        subheading:"Facebook",
    },
    {
        id:3,
        icon:twitterLogo,
        heading:"15.2k",
        subheading:"Twitter",
    },
    {
        id:4,
        icon:linkdeinLogo,
        heading:"17.2k",
        subheading:"Linkedin",
    },
    {
        id:5,
        icon:pinterstLogo,
        heading:"17.2k",
        subheading:"Pinterest",
    },
    {
        id:6,
        icon:tiktokLogo,
        heading:"17.2k",
        subheading:"Tik Tok",
    },

] 
export const data1 = [
    {
      title: "Total Posts",
      icon: profileIcon1,
      shareQuantity: "1,81",
      percentage:  "12.5%"
    },
    {
      title: "Total Likes",
      icon: profileIcon2,
      shareQuantity: "892",
      percentage:  "12.5%"
    },
    {
      title: "Total Comments",
      icon: profileIcon3,
      shareQuantity: "980",
      percentage:  "12.5%"
    },
    {
        title: "Total Shares",
        icon: profileIcon4,
        shareQuantity: "120",
        percentage: "12.5%"
      },
   
  ];
  export const postalldata=[
    {
        image:postall1,
        heading:"Text Post",
        paragraph:"Post Texts automatically on Social Media",
        path:'/textpost'
    },
    {
        image:postall2,
        heading:"Image Post",
        paragraph:"Post Texts automatically on Social Media",
        path:'/imagepost'
    },
    {
        image:postall3,
        heading:"Video Post",
        paragraph:"Post Texts automatically on Social Media",
        path:'/videopost'
    },
    {
        image:postall4,
        heading:"Reels Post",
        paragraph:"Post Texts automatically on Social Media",
        path:'/reelspost'
    },
    {
        image:postall3,
        heading:"Stories Post",
        paragraph:"Post Texts automatically on Social Media",
        path:'/storiespost'
    },
  ]
 
export default data;
