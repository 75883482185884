import alikhan from '../images/alikhan.svg'

const rowsData = [
    {
      name: 'Ali Khan',
      imageSrc: alikhan,
      date: 'Apr 12 2023',
      time: 'At 08:00 AM',
      signalCount: 2,
      status: 'Paid',
    },
    {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'Unpaid',
      },
      {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'Paid',
      },
      {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'Unpaid',
      },
      {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'Paid',
      },
      {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'UnPaid',
      },
      {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'Paid',
      },
      {
        name: 'Ali Khan',
        imageSrc: alikhan,
        date: 'Apr 12 2023',
        time: 'At 08:00 AM',
        signalCount: 2,
        status: 'UnPaid',
      },
    // Add more data objects as needed for additional rows
  ];
  export default rowsData