import React from 'react'
import Chart from '../component/Chart';
import chartbackground  from '../images/chartland.png'
const Chartlandingpage = () => {
    return (
        <div className="lg:h-[500px] lg:flex lg:p-5 lg:my-[100px] w-full " style={{backgroundImage:  `url(${chartbackground})`,backgroundRepeat:"no-repeat",backgroundSize: " contain", backgroundPosition: "left bottom"}}>
            <div className="w-full lg:w-1/2 py-20 px-4">
                <h1 className="lg:w-[587px] text-violet-950 lg:text-[40px] md:pb-5 font-bold  md:leading-[46.80px]">Write articles that blend content with optimization</h1>
                <div className="lg:w-[445px] text-zinc-900 text-base lg:text-lg font-normal  leading-loose">List a product once and it will be published across all of your connected sales channels. Similarly, when you make changes to your listing</div>
            </div>
            <div className="lg:w-1/2   md:py-[65px] px-4 ">
            <div className=' shadow rounded-4 h-full bg-white w-full md:rotate-12'>
              <div className='d-flex'>
                <span className='flex flex-col'>
                <h1 className=' ms-3 text-[#290064] font-inter text-sm font-normal'>Instagram </h1>
                <h1 className='ms-3 text-sm font-semibold lineargradient'>Followers: 11.2k</h1>
                </span>
               
                <button className='btn btn-sm  text-[#615E83] ml-auto border bg-gray-100 d-flex h-[25px] w-[100px] rounded-5 px-4 mt-2 me-3'>Monthly
                  <svg className=' ms-5' xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.256252 0.872584C0.0148435 1.11399 0.0148431 1.50539 0.256251 1.7468L4.46423 5.95478C4.46835 5.95915 4.47255 5.96347 4.47682 5.96774C4.59757 6.08849 4.75585 6.14884 4.91412 6.14879C5.07238 6.14884 5.23066 6.08849 5.35141 5.96774C5.35568 5.96347 5.35988 5.95915 5.364 5.95478L9.57198 1.7468C9.81339 1.50539 9.81339 1.11399 9.57198 0.872584C9.33057 0.631176 8.93917 0.631176 8.69776 0.872584L4.91411 4.65623L1.13047 0.872584C0.889059 0.631176 0.49766 0.631176 0.256252 0.872584Z" fill="#615E83" />
                  </svg>
                </button>
              </div> 
              <Chart />
            </div>

            </div>
        </div>
    )
}

export default Chartlandingpage