import React from 'react'

const SocialMediaCompo = ({icon,heading,subheading}) => {
  return (
    <>
    <div className='w-full h-full p-2 rounded-4  shadow  d-flex justify-content-center hover:bg-purple-100 align-items-center mt-4 '>
        <span className='d-flex'>
        <img className='h-10 w-10' src={icon} alt=''/>
        <span className='d-flex flex-column'>
        <h6 className='mt-1 text-sm lg:text-lg ps-1'>{heading}</h6>
       <p className='fw-2 text-sm text-blue-gray-900 font-semibold ps-1' style={{width: '100%', height: '100%', color: '#A0AEC0', fontSize: 12, fontFamily: 'Roboto', fontWeight: '700', whiteSpace: 'nowrap'}}>{subheading}</p>
        </span>
       
        </span>
      
    </div>
    </>
  )
}

export default SocialMediaCompo