import React from 'react'

const Input = (props) => {
    const { type, name, id, placeholder, required, value, onChange, className} = props;
  return (
    <input
    type={type}
    name={name}
    id={id}
    value={value}
    onChange={onChange}
    className={className}
    placeholder={placeholder}
    required={required}
/>
  )
}

export default Input