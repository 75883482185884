import React from 'react'
import image1 from '../images/note.svg'
import image2 from '../images/money.svg'
import image3 from '../images/clock.svg'
import image4 from '../images/bank.svg'
import image5 from '../images/refmodal.svg'
import alikhan from '../images/alikhan.svg'


const ReferalModal = ({ closeModal }) => {
    return (
        <div>
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                {/* Background backdrop */}
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex justify-center items-center">
                    <div className="w-[17.5rem] md:w-[24rem] md:h-[454px] overflow-auto bg-white rounded-2xl shadow px-4">
                        <span className='flex justify-center md:text-xl font-semibold mt-3'>Reciept</span>
                        <svg onClick={closeModal} className='h-3 relative -top-5 left-[14rem] md:left-[20rem]' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M1 15L15 1M1 1L15 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div className='flex justify-center'>
                        <img className="w-[65px] h-[65px] rounded-full flex justify-center mb-2" src={alikhan} />
                        </div>
                        <h3 className="text-black md:text-[18px] font-bold font-['Inter'] flex justify-center mb-2">Muhammad Sameer</h3>

                    
                    <div className='flex justify-between my-3'>
                        <span className='flex'>
                        <img src={image2} className='me-2' />
                        <span className='text-black text-sm md:text-base font-semibold'> Amount</span>
                        </span>
                        <span className='text-black text-sm md:text-base font-semibold'>300$</span>
                    </div>
                    <hr/>
                    <div className='flex justify-between my-3'>
                        <span className='flex'>
                        <img src={image4}  className='me-2'/>
                        <span className='whitespace-nowrap text-black text-sm md:text-base font-semibold'>Bank Account</span>
                        </span>
                        <span className='ms-4 text-black text-sm md:text-base font-semibold'>BBBBAAAAAAAAAP-UBL</span>
                    </div>
                    <div className='flex justify-between my-3'>
                        <span className='flex'>
                        <img src={image1}  className='me-2'/>
                        <span className='text-black text-sm md:text-base font-semibold'>Schedule</span>
                        </span>
                        <span className='text-black text-sm md:text-base font-semibold'>Now</span>
                    </div>
                    <div className='flex justify-between my-3'>
                        <span className='flex'>
                        <img src={image3} className='me-2' />
                        <span className='text-black text-sm md:text-base font-semibold'>Time</span>
                        </span>
                        <span className='text-black text-sm md:text-base font-semibold'>9:45</span>
                    </div>
                    <hr/>
                    <div className='flex justify-between my-3'>
                        <span className='flex'>
                        <img src={image5} className='me-2'/>
                        <span className='text-black text-sm md:text-base font-semibold'>Note</span>
                        </span>
                        <span className='text-black text-sm md:text-base ms-4 md:ms-0 font-semibold'>Thank You for Your Hard Work</span>
                    </div>
                                   
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ReferalModal