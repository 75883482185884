import React from 'react'
import Navbar from '../component/Navbar'
import SocialMediaCompo from '../component/SocialMediaCompo';
import data from '../component/SocialData';
import { data1 } from '../component/SocialData';
import Chart from '../component/Chart';
import { table } from '../component/SidebarData';
import Stats from '../component/Stats';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useEffect } from 'react'
import CustomDropdown from '../component/CustomDropdown';
import SocialMediaDropdown from '../component/SocialMediaDropdown';

const stripe = require('stripe')("sk_test_51OOvc9EFjff6I8CprZVMxaowAtmmqJvryH9zWT3gi4SDF850JJMc3SpoGtIV6SZgILe25Nqt9RA3F4q65Zt4JUcd00RADIPeyg")
const db = getFirestore();

const Dashboard = () => {

  useEffect(() => {
    const getDetails = async () => {
      try {
        // Step 1: Retrieve the session ID from localStorage
        const id = localStorage.getItem('session_id');
        console.log("this is id", id);

        // Step 2: Use the Stripe SDK to retrieve details about the Checkout session
        const sessionDetails = await stripe.checkout.sessions.retrieve(id);
        console.log(sessionDetails);
        const { // Extract the properties you need from sessionDetails
          id: sessionId,
          amount_total,
          created,
          currency,
          expires_at,
          customer_details: { email, name },
          // ... add other properties you want to extract
        } = sessionDetails;
        const amount = amount_total / 100;
        // Step 3: Store the session details in Firestore
        const sessionRef = doc(db, 'payment', id); // Use a collection name of your choice
        await setDoc(sessionRef, {
          sessionId,
          amount,
          created,
          expires_at,
          currency,
          customer_details: {
             // Include the entire address object
            email,
            name,
          },
          timestamp: new Date(),
        });
        localStorage.removeItem('session_id')
        console.log('Session details stored in Firestore successfully');
      } catch (error) {
        console.error('Error storing session details in Firestore:', error);
      }
    };

    // Step 4: Call the `getDetails` function when the component mounts
    getDetails();
  }, []);
  return (
    <AuthenticatedLayout>

      <Navbar heading="Dashboard" showbuton={false} />
      <div className='px-4 bg-gray-200 sm:ml-0 lg:ml-4 ' style={{ marginLeft: "", maxWidth: "fit-content", marginRight: "" }}>
        <div className='row '>
          <div className='row p-0 m-0  '>
            <div className='social-container my-3 pe-3 col-12 border rounded-3 bg-white '>
              <h1 className='text-xl lineargradient font-semibold mt-5 ms-4 mb-2  '>Social Media Follower</h1>
              <div className='row ps-2 pe-2 '>
                {data.map((d, index) => (
                  <div key={index} className='w-1/2 lg:w-1/6 mt-2 mb-5'>
                    <SocialMediaCompo icon={d.icon} heading={d.heading} subheading={d.subheading} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='row p-0 m-0' >
            {/* <div className='w-[610px] me-4 border rounded-3 bg-white'> */}
            <div className='col-12 col-md-7 border rounded-3 bg-white'>
              <div className='d-flex'>
                {/* <span className='flex flex-col'>
                  <h1 className='mt-3 ms-3 text-[#290064] font-inter text-xl font-normal'>Instagram </h1>
                  <h1 className='ms-3 text-xl font-semibold lineargradient'>Followers: 11.2k</h1>
                </span> */}
                <SocialMediaDropdown/>
                <div className='ml-auto'>
                <CustomDropdown/>
                </div>
              </div>
              <Chart />
            </div>
            <div className='col-12 col-md-5 border rounded-3 bg-white  '>
              <h1 className='fs-5 mt-4 ms-3 text-xl font-semibold lineargradient'>Profile Stats</h1>
              <div className='flex flex-wrap mt-4 p-0 justify-content-center'>
                {data1.map((item, index) => (
                  <div key={index} className=' mt-2 w-1/2 lg:w-1/2'>
                    <Stats title={item.title} icon={item.icon} shareQuantity={item.shareQuantity} percentage={item.percentage} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='row p-0 m-0'>
            <div className=' my-3 col-12 border rounded-3 overflow-x-auto bg-white'>
              <h1 className='fs-5 mt-3 text-xl font-semibold lineargradient'>Latest Post Performance</h1>
              <table className='table '>
                <thead>
                  <tr className='tablehead'>
                    <td style={{ color: "#290064" }}>Social</td>
                    <td style={{ color: "#290064" }}>Post</td>
                    <td style={{ color: "#290064" }}>Engagement</td>
                    <td style={{ color: "#290064" }}>View</td>
                    <td style={{ color: "#290064" }}>Likes</td>
                    <td style={{ color: "#290064" }}>Shares</td>
                  </tr>
                </thead>
                <tbody>
                  {table.map((data) => (
                    <tr key={data.id} className=''>
                      <td>
                        <img className='w-5 h-5' src={data.image} alt={data.post} />
                      </td>
                      <td style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.post}</td>
                      <td style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.percent}</td>
                      <td style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.views}</td>
                      <td style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.likes}</td>
                      <td style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.share}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </AuthenticatedLayout>
  )
}

export default Dashboard