import React from 'react'
import Button from '../component/Button'
import dashboarimage from '../images/Dashboard.svg'
import herosecionimage from '../images/herobackground.png'
import { Link } from 'react-router-dom'
const Herosection = () => {
    return (
        <div className='bg-violet-50 my-auto w-full lg:h-[774px] flex flex-col lg:flex-row  px-auto' style={{backgroundImage:  `url(${herosecionimage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
            <div className='row col-11 sm:ms-5 mx-auto my-auto sm:pt-5'>
            <div className='lg:w-1/2 md:1/2 px-3'>
                <div className="lg:w-[550px] text-violet-950 lg:text-[50px] font-bold  tracking-wide ">Enjoy the management of your Accounts</div>
                <div className="lg:w-[473px] lg:h-[39px] mb-4 opacity-80 text-zinc-700 text-sm  lg:text-lg font-normal leading-relaxed tracking-tight lg:pt-5 lg:pb-[75px]">Get high-quality content that drives organic traffic to your blog, by boosting your DA.</div>
                <Link to='/login'>
                <Button className="w-[120px] lg:w-[235px]  h-[30px] lg:h-[60px] bg-violet-600 hover:bg-violet-800 hover:border-purple-900 rounded-[10px] text-white text-sm mb-3 lg:text-lg font-semibold" val='Get Started' />
                </Link>
            </div>
            <div className='lg:w-1/2 md:1/2 '>
                <img src={dashboarimage} alt='img' className=' ' />
            </div>
            </div>
        </div>
)
}

export default Herosection