import Icon from '../images/Ellipse 433.png'

const fields = [
    {
      name: 'first_name',
      label: 'First Name',
      type: 'text',
      id: 'first_name',
      placeholder: 'Enter first name',
      required: true,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      type: 'text',
      id: 'last_name',
      placeholder: 'Enter last name',
      required: true,
    },
    
  ];
  export default fields
  export const notification = [
    {
        image:Icon,
        heading:'Richard Miles',
        paragraph:'Cheesy feet cheesy grin say cheese. Smelly cheese boursin red leicester parmesan cheese and wine bavarian bergkase danish',
        time:'10 hour ago',
        timelimit:'6:45am',
      },
      {
        image:Icon,
        heading:'Richard Miles',
        paragraph:'Cheesy feet cheesy grin say cheese. Smelly cheese boursin red leicester parmesan cheese and wine bavarian bergkase danish ',
        time:'10 hour ago',
        timelimit:'6:45am',
      },
      {
        image:Icon,
        heading:'Richard Miles',
        paragraph:'Cheesy feet cheesy grin say cheese. Smelly cheese boursin red leicester parmesan cheese and wine bavarian bergkase danish',
        time:'10 hour ago',
        timelimit:'6:45am',
      },
      {
        image:Icon,
        heading:'Richard Miles',
        paragraph:'Cheesy feet cheesy grin say cheese. Smelly cheese boursin red leicester parmesan cheese and wine bavarian bergkase danish',
        time:'10 hour ago',
        timelimit:'6:45am',
      },
      {
        image:Icon,
        heading:'Richard Miles',
        paragraph:'Cheesy feet cheesy grin say cheese. Smelly cheese boursin red leicester parmesan cheese and wine bavarian bergkase danish',
        time:'10 hour ago',
        timelimit:'6:45am',
      },
      {
        image:Icon,
        heading:'Richard Miles',
        paragraph:'Cheesy feet cheesy grin say cheese. Smelly cheese boursin red leicester parmesan cheese and wine bavarian bergkase danish',
        time:'10 hour ago',
        timelimit:'6:45am',
      },
    // Add more notifications as needed
  ];

  export const billingtable = [
    {
      pack:'Regular',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Basic',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Basic',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Basic',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Premeium',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Premeium',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Premeium',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Premeium',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
    {
      pack:'Premeium',
      paymentid:'0124123121',
      purDate:'29-09-2023',
      expDate:'29-09-2023',
      amountPaid:'Amount Paid'
    },
  ]