import { useState } from 'react';
import { NavLink } from 'react-router-dom';
 import Logo from '../images/newsocillogo.svg';
import { Link } from 'react-scroll';


export default () => {
  const [state, setState] = useState(false);

  const navigation = [
    { title: "Features", path: "/features" },
    { title: "Pricing", path: "/pricing" },
    { title: "FAQS", path: "/faqs" }
  ];

  return (
    <>
      <nav className={`relative z-20 bg-white container md:static md:text-sm md:border-none ${state ? "shadow-lg rounded-b-xl md:shadow-none" : ""}`}>
        <div className="items-center gap-x-14 px-4 mx-auto md:flex md:px-8">
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
                    <img src={Logo} className=" lg:pl-[14px] h-4 md:h-full  sm:pl-10 me-2" />

            <div className="md:hidden">
              <button className=""
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-violet-600" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-violet-600">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className={`nav-menu flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${state ? 'block' : 'hidden'}`}>
            <ul className="mt-3 ">
              <div class="md:flex bd-highlight">
                <div class="p-2 flex-grow-1 bd-highlight flex align-items-center justify-content-center"> 
                <li className='flex items-center  md:flex-row flex-col -mt-5'>
                  <Link spy={true} smooth={true} offset={-100} className='me-3 h-5 py-3 cursor-pointer text-violet-950 text-base font-normal' duration={500} to="features">
                    Features
                    </Link>
                    <Link spy={true} smooth={true} offset={-100} className='me-3 h-5 py-3 cursor-pointer text-violet-950 text-base font-normal' duration={500} to="pricing">
                    Pricing
                    </Link>
                    <Link spy={true} smooth={true} offset={-100} className='me-3 h-5 py-3 cursor-pointer text-violet-950 text-base font-normal' duration={500} to="faqs">
                    FAQS
                    </Link>
                </li></div>
                <div class="p-2 bd-highlight"><NavLink className="" to="/login">
                    <button className="whitespace-nowrap hover:bg-violet-600  hover:text-white text-violet-950 text-sm lg:text-base rounded-3 md:w-[99px] md:h-[31px] w-full h-[35px] lg:w-[99px] lg:h-[31px] ">
                      Login
                    </button>
                  </NavLink></div>
                <div class="p-2 bd-highlight"><NavLink to="/signup">
                    <button className="whitespace-nowrap bg-violet-600 hover:bg-violet-900 text-sm lg:text-base text-white rounded-3  w-full h-[35px] md:w-[99px] md:h-[31px] lg:w-[99px] lg:h-[31px] ">
                      Sign Up
                    </button>
                  </NavLink></div>
              </div>
               
              {/* <div className='flex-1 items-center justify-end gap-x-6 space-y-3 md:flex md:space-y-0'>
                <li>
                  <NavLink className="" to="/login">
                    <button className="whitespace-nowrap hover:bg-violet-600  hover:text-white text-violet-950 text-sm lg:text-base rounded-3 md:w-[99px] md:h-[31px] w-full h-[35px] lg:w-[99px] lg:h-[31px] ">
                      Login
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/signup">
                    <button className="whitespace-nowrap bg-violet-600 hover:bg-violet-900 text-sm lg:text-base text-white rounded-3  w-full h-[35px] md:w-[99px] md:h-[31px] lg:w-[99px] lg:h-[31px] ">
                      Sign Up
                    </button>
                  </NavLink>
                </li>
              </div> */}
            </ul>
          </div>
        </div>
      </nav>
      {state && (
        <div
          className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
          onClick={() => setState(false)}
        ></div>
      )}
    </>
  );
};
