import Icon from '../images/Dashboardicon.png';
import Icon1 from '../images/subscription.png';
import Icon2 from '../images/aiweb.png';
import Icon3 from '../images/social.png'
import Icon4 from '../images/aipic.png';
import Icon5 from '../images/api-interface_svgrepo.com.png';
import Icon6 from '../images/aipic.png';
import Icon7 from '../images/usersearch.png';
import instaLogo from '../images/inst.png';
import facebookLogo from '../images/face.png';
import twitterLogo from '../images/twit.png';
import tiktokLogo from '../images/tik.jpg';
import linkdeinLogo from '../images/link.png';
import pinterstLogo from '../images/pin.png';
import tablelinkedinlogo from '../images/linkedin.png';
import tablefacebooklogo from '../images/facebook.png';
import tablepinterestlogo from '../images/pinterest.png';
import tabletiktoklogo from '../images/tiktok.png';
import instaLogo1 from '../images/insta.png';

let routes = [
    {
        id:Math.random(),
        path:"/dashboard",
        image:Icon,
        name:"Dashboard"
    },
    {
        id:Math.random(),
        path:"/subscription",
        image:Icon1,
        name:"Subscription"
    },
    {
        id:Math.random(),
        path:"/apichannels",
        image:Icon2,
        name:"Api Channels"
    },
    {
        id:Math.random(),
        path:"/socialposting",
        image:Icon3,
        name:"Social Posting",
        childerns:[
            {
                id:Math.random(),
                path:"/post",
                image:Icon3,
                name:"Post all Platfrom"
            },
            {
                id:Math.random(),
                path:"/socialposting/insta",
                image:instaLogo,
                name:"Instagram"
            },
            {
                id:Math.random(),
                path:"/socialposting/face",
                image:facebookLogo,
                name:"Facebook"
            },
            {
                id:Math.random(),
                path:"/socialposting/linkd",
                image:linkdeinLogo,
                name:"Linkedin"
            },
            {
                id:Math.random(),
                path:"/socialposting/twitt",
                image:twitterLogo,
                name:"Twitter"
            },
            {
                id:Math.random(),
                path:"/socialposting/pinter",
                image:pinterstLogo,
                name:"Pinterest"
            },
            {
                id:Math.random(),
                path:"/socialposting/tik",
                image:tiktokLogo,
                name:"Tik Tok"
            },
           
        ]
    },
    {
        id:Math.random(),
        path:"/aicontentgenrator",
        image:Icon4,
        name:"AI Content generator"
    },
    {
        id:Math.random(),
        path:"/aipicturegenerator",
        image:Icon5,
        name:"AI Picture generator"
    },
    {
        id:Math.random(),
        path:"/aiwebsitebuilder",
        image:Icon6,
        name:"AI Website Builder"
    },
    {
        id:Math.random(),
        path:"/referal",
        image:Icon7,
        name:"Referals"
    }
]
export default routes

export const table = [
      {
        id:Math.random(),
         image:tablelinkedinlogo,
         post:'Post on Linkdein(ETC)',
         percent:"60%",
         views:2000,
         likes:3000,
         share:1000,
       },
       {
        id:Math.random(),
        image:tablefacebooklogo,
        post:'Post on Facebook(ETC)',
        percent:"60%",
        views:2000,
        likes:3000,
        share:1000,
      },
      {
        id:Math.random(),
        image:tablepinterestlogo,
        post:'Post on Twitter(ETC)',
        percent:"60%",
        views:2000,
        likes:3000,
        share:1000,
      },
      {
        id:Math.random(),
        image:tabletiktoklogo,
        post:'Post on Pinterest(ETC)',
        percent:"60%",
        views:2000,
        likes:3000,
        share:1000,
      },
]


export const table1 = [
    {
      id:Math.random(),
       image:instaLogo1,
       post:'22k',
       percent:"60%",
       views:2000,
       likes:3000,
       share:1000,
     },
     {
      id:Math.random(),
      image:tablefacebooklogo,
      post:'45k',
      percent:"60%",
      views:2000,
      likes:3000,
      share:1000,
    },
    {
      id:Math.random(),
      image:tablepinterestlogo,
      post:'33k',
      percent:"60%",
      views:2000,
      likes:3000,
      share:1000,
    },
    {
      id:Math.random(),
      image:tabletiktoklogo,
      post:'10k',
      percent:"60%",
      views:2000,
      likes:3000,
      share:1000,
    },
]
