import React from 'react'
import GuestLayout from '../Layouts/GuestLayout'
import { useNavigate } from 'react-router-dom'

const PrivacyPolicy = () => {
    const navigate = useNavigate()
    const handleBack = () => {
        navigate('/')
    }
    return (
        <GuestLayout>
            <>
                <div className='bg-[#F5F6FA] '>
                    <center >
                        <h1 className='text-[34px] font-semibold p-3 text-gray-500'>Privacy Policy</h1>
                        <p className='text-[20px] text-gray-500 p-3 text-bold w-full md:w-[810px]'>We are on a mission to make the web a better place. The following Policies, as well as our Policy and Privacy Service, apply to all users.
                        </p>
                        <p className='mb-3 text-[16px] text-gray-400 italic'>
                        Last Update: Jan 01, 2024
                        </p>
                    </center>
                    <center className='border mx-5 bg-white rounded-1  p-4 text-start text-gray-500'>
                        <h1 className='flex justify-start text-[28px] font-bold text-gray-600'>Privacy Policy</h1><br />
                        <p className='flex justify-start'>We’ve decided to make these Policy available under a Creative Commons license. You can grab a copy of these Policies and other legal documents on Github. You’re more than welcome to copy them, adapt them, and repurpose them for your own use.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Introduction</h2>
                        <br />
                        <p>
                            Welcome to Socialify.ai's Privacy Policy. This document outlines how we collect, use, disclose, and safeguard your personal information when you use our software and services (referred to as the "Service"). By accessing or using our Service, you agree to the terms outlined in this Privacy Policy.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Information We Collect</h2><br />
                        <p>
                            <strong>Account Information:</strong> When you register for an account, we collect and store the information you provide during the registration process, including your name, email address, and any other relevant details.
                        </p>
                        <strong> Usage Information:</strong> We may collect information about how you interact with our Service, including your usage patterns, preferences, and interactions with user-generated content.
                        <br />                <br />

                        <h2 className='text-[28px] font-bold text-gray-600'>How We Use Your Information</h2><br />
                        <p>
                            We use the collected information for the following purposes:
                        </p><br />
                            <ul>
                                <li><strong>Providing and Improving the Service:</strong> To deliver the Service, personalize your experience, and enhance its features and functionality.</li>
                                <li><strong>Communications:</strong>To send you updates, alerts, and other essential information related to the Service.</li>
                                <li><strong>User-Generated Content:</strong>To display and manage user-generated content in connection with the Service.</li> 
                            </ul>
                            <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Information Sharing</h2>
                        <br />
                        <p>We may share your personal information in the following circumstances:</p><br/>
                        <ul>
                            <li><strong>With Your Consent:</strong>We may share your information when you give us explicit consent to do so.</li>
                            <li><strong>Service Providers: </strong>We may engage third-party service providers to assist with various aspects of the Service, and they may have access to your information.</li>
                        </ul>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>User Control and Choices</h2>
                        <br />
                        <p>You can control your personal information in the following ways:</p><br/>
                        <ul>
                            <li><strong>Account Settings:</strong>You can update and manage your account information through the account settings.</li>
                            <li><strong>Communication Preferences:</strong>You can manage your communication preferences and opt-out of non-essential communications.</li>
                        </ul>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Security</h2>
                        <br />
                        <p>
                        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is entirely secure.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Amendments to Privacy Policy</h2>
                        <br />
                        <p>
                        We reserve the right to modify or update this Privacy Policy at any time. We will notify you of significant changes through the Service or other means.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Governing Law</h2>
                        <br />
                        <p>
                        This Privacy Policy is governed by the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Contact Us</h2>
                        <br />
                        <p>
                        If you have any questions about this Privacy Policy, please contact us at [team@novabuzzmarketing.com].</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Entire Agreement</h2>
                        <br />
                        <p>
                        This Privacy Policy constitutes the entire agreement between you and Socialify.ai regarding the collection and use of your information, superseding any prior agreements.
                        </p>
                        <br />
                        
                        <button onClick={handleBack} className='border rounded-1 px-3 hover:bg-violet-900  hover:text-white'>Back
                        </button>
                    </center>
                </div>
            </>
        </GuestLayout>
    )
}

export default PrivacyPolicy