import { Button } from 'bootstrap'
import { Input } from 'postcss'
import React from 'react'

const DisconnectModal = ({closeModal}) => {
  return (
    <div>
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              {/* Background backdrop */}
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex justify-center items-center">
                
                <div className="flex  lg:min-h-full  p-4 text-start  sm:items-center sm:p-0">
                  <div className="w-full sm:w-[486px] bg-white rounded-[10px] shadow border border-zinc-200 mx-auto px-5 py-4 sm:p-0">
                    <div className="flex flex-col justify-center items-center sm:items-start">
                      <div className="mt-4 mb-4  mx-auto">
                          <p className="flex flex-col justify-center items-center w-full lg:w-[337px] text-gray-700 text-xl font-medium  leading-7">Are You Sure You Wann Disconnect?</p>
                        <br />
                        <div className='flex  justify-center items-center gap-5'>
                        <button onClick={closeModal} className="w-[50px] lg:w-[95px] h-fit lg:h-[43px]  rounded-[5px] border-1 text-xl text-violet-600 border-violet-600 hover:bg-violet-600 hover:text-white font-semibold">Yes</button>
                        <button onClick={closeModal} className="w-[50px] lg:w-[95px] h-fit lg:h-[43px] rounded-[5px] border-1 text-xl text-violet-600 hover:bg-violet-600 hover:text-white border-violet-600 font-semibold">No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    </div>
  )
}

export default DisconnectModal