import React, { useState } from 'react';

import { RiArrowDropDownLine } from "react-icons/ri";



const CustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('This Week');

  const options = ['This Week', 'This Month', 'Last Week'];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="mt-2 flex  text-[#615E83] ml-auto border bg-gray-100 text-[15px] h-[32px] w-fit rounded-4 p-1 focus:outline-none cursor-pointer"
      >
         <span className="mr-4 ml-2">{selectedOption}</span>
         <RiArrowDropDownLine size={25} />
 
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white border border-gray-300 text-[15px] lg:w-[120px] w-[100px] rounded-1 p-1">
          {options.map((option) => (
            <div
              key={option}
              onClick={() => handleOptionClick(option)}
              className="hover:bg-gray-200 p-1 cursor-pointer"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
