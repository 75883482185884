import React from 'react'
import { postalldata } from '../component/SocialData'
import PostallPlatform from './PostallPlatform'
import Navbar from '../component/Navbar'
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout'
import { Link, useParams } from 'react-router-dom'
const Instagram = () => {
  const { platform } = useParams();
  return (
    <AuthenticatedLayout>
      <Navbar heading="Instagram" showbuton={true} />
      <div className='container-fluid  mt-4'>
        <h1 className="text-violet-950 lg:text-xl ps-4 font-bold  mb-1 mt-4">Choose Post Type</h1>
        <p className="text-neutral-600 text-sm ps-4 lg:text-base font-normal ">Here You Can Choose Posting Type to Post in Your Social Media</p>
        <div className='container flex flex-wrap'>
          {postalldata.map((item, index) => (
            <div key={index} className='mx-auto lg:w-1/5 w-1/2'>
              <Link to={item.path}>
                <PostallPlatform className='bg-white' icon={item.image} heading={item.heading} paragraph={item.paragraph} platform={platform} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default Instagram