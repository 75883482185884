import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

const SocialMediaDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [followerCounts, setFollowerCounts] = useState({
    Instagram: '11.2k',
    Facebook: '5.8k',
    Linkedin:'23.1k',
    Pinterest:'12.3k',
    // Add follower counts for other platforms
  });

  const options = Object.keys(followerCounts);

  const handleOptionClick = (option) => {
    if (selectedOption === option) {
      // If the selected option is clicked again, close the dropdown
      setSelectedOption(null);
      setIsOpen(false);
    } else {
      // Otherwise, update the selected option and open the dropdown
      setSelectedOption(option);
      setIsOpen(true);
    }
  };

  return (
    <div className="relative inline-block">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="mt-2 flex  border rounded lineargradient h-10 font-inter text-xl font-normal p-1 focus:outline-none cursor-pointer"
      >
        <span className="mr-4 ml-2">
          {selectedOption ? `${selectedOption} - Followers: ${followerCounts[selectedOption]}` : 'Select The Platform'}
        </span>
        <RiArrowDropDownLine style={{ color: '#9856AB' }} className="mt-1 " size={25} />
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white border border-gray-300 text-[15px] lg:w-[120px] w-[100px] rounded-1 p-1">
          {options.map((option) => (
            <div
              key={option}
              onClick={() => handleOptionClick(option)}
              className="hover:bg-gray-200 p-1 cursor-pointer"
            >
              <div>{option}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SocialMediaDropdown;
