import React, { useState } from 'react';
import Input from './Input'; // Import the 'Input' component.
import { CiSearch } from "react-icons/ci";

const Search = () => {
   
    const [postType, setPostType] = useState('image');
    const handleclick = () => {
        
    }
    return (
        <>
            {/* Start of the search bar */}
            
             <div className='flex mt-4 w-full'>
                <div className='relative flex items-stretch w-full'>
                    <div className='relative group flex items-center w-90 w-full  lg:h-[63px]'> 
                        {/* Input element for searching */}
                         <Input
                            type='text'
                            className='form-control text-neutral-600 text-sm lg:text-xl font-normal px-4 w-full lg:h-[55px] h-[40px]  bg-white rounded-[50px] border border-zinc-200 pr-[200px]'
                            placeholder='Search'
                            aria-label="Recipient's username"
                            aria-describedby='basic-addon2'
                        /> 
                        {/* Button for initiating the search */}
                         <div className='absolute inset-y-1 right-0 flex items-center d-none d-lg-block'>
                            <button className=' lg:w-[12rem] w-[7rem] lg:h-[55px] h-[40px] bg-violet-100 rounded-[50px] shadow  lg:p-2 lg:pe-4 lg:ps-4 border-1 text-violet-600 lg:text-lg text-xs ' onClick={handleclick} style={{border:'1px solid'}}>Search</button>
                        </div>
                        <div className='absolute inset-y-1 right-4 top-2 flex items-center d-block d-lg-none z-1'>
                        <CiSearch size={22}/>                      
                          </div>
                    </div>
                </div>
            </div> 
            {/* End of the search bar */}

            {/* Link for navigating to the 'createpost' route */}
            
              
        </>
    );
};

// Export the 'Search' component for use in other parts of your application.
export default Search;
