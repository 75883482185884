import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Subscription from '../pages/Subscription';
import subscriptiondata from './Subscriptiondata';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
import Navbar from '../component/Navbar'
import PurchaseModal from '../pages/PurchaseModal';

import { loadStripe } from '@stripe/stripe-js';

const stripe = require('stripe')("sk_test_51OOvc9EFjff6I8CprZVMxaowAtmmqJvryH9zWT3gi4SDF850JJMc3SpoGtIV6SZgILe25Nqt9RA3F4q65Zt4JUcd00RADIPeyg");

const Subscriptionmap = () => {
  
  const paymentData = [
    {
      name: 'Basic Plan',
      price: 10
    },
    {
      name: 'Standard Plan',
      price: 20
    },
    {
      name: 'Premium Plan',
      price: 30
    }
  ];
  console.log(paymentData)
  
  const payment = async (selectedPlan) => {
    const strip = await loadStripe('pk_test_51OOvc9EFjff6I8CpD9tN7fnGvIPOPkHcIPWwaqQWCYUCKEwZ1DMPAXmgfatRHvDfQIeD2c5fgt5DDbapF6dhgK8k004BnJqPIF')

    
    const lineItems = [
      {
        price_data: {
          currency: 'usd',
          product_data: {
            name: selectedPlan.name,
          },
          unit_amount: selectedPlan.price * 100,
        },
        quantity: 1,
      }
    ];
    console.log(lineItems);

    const session = await stripe.checkout.sessions.create({
      payment_method_types: ['card'],
      line_items: lineItems,
      mode: 'payment',
      success_url: 'http://localhost:3000/dashboard',
      cancel_url: 'http://localhost:3000/',
    });

    localStorage.setItem("session_id",session.id)
    
  

    const result = await strip.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error);
    }

  }
  const [showmodal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    
    responsive: [
      {
        breakpoint: 992, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect will only run once on mount

  return (
    <AuthenticatedLayout>
      <Navbar heading="Subscription" showbuton={true} />
      {
        showmodal && <PurchaseModal isOpenmodal={showmodal} isClosemodal={handleCloseModal} />
      }

      {isSmallScreen ? (
        <Slider {...settings}>
          {subscriptiondata.map((item, index) => (
            <div key={index} className='px-5'>
              <Subscription
                head={item.heading}
                facili1={item.facility1}
                facili2={item.facility2}
                facili3={item.facility3}
                facili4={item.facility4}
                facili5={item.facility5}
                facili6={item.facility6}
                facili7={item.facility7}
                facili8={item.facility8}
                subhead={item.subheading}
                button={item.button}
                payment={() => payment(paymentData[index])}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="flex flex-wrap justify-center gap-5">
          {subscriptiondata.map((item, index) => (
            <div key={index} className="w-[206px] md:w-1/4 lg:w-1/4">
              <Subscription
                head={item.heading}
                facili1={item.facility1}
                facili2={item.facility2}
                facili3={item.facility3}
                facili4={item.facility4}
                facili5={item.facility5}
                facili6={item.facility6}
                facili7={item.facility7}
                facili8={item.facility8}
                subhead={item.subheading}
                button={item.button}
                onclick={handleOpenModal}
                payment={() => payment(paymentData[index])}
              />
            </div>
          ))}
        </div>
      )}
   
    </AuthenticatedLayout>
  );
};

export default Subscriptionmap;
