import GuestLayout from '../Layouts/GuestLayout';
import { Link, useNavigate } from 'react-router-dom';
import Socialify from '../images/socialify.png';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import back from '../images/Loginbackimage.png';
import { useEffect, useState } from 'react';
import { auth } from "../firebase-config/firebase";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { doc,setDoc,getDoc,getFirestore} from 'firebase/firestore';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignInButtonClicked, setIsSignInButtonClicked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState({
    username: '',
    email: '',
    password: '',
  });
 
  const googleProvider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  // const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[<>]).{8,}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    // Check the user's authentication status
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      // User is already authenticated, redirect to the dashboard
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      onAuthStateChanged(auth, async (user) => {
    //      if (/[<>?]/.test(email) || /[<>?]/.test(password)) {
    // // Invalid characters found in email or password
    // setError({
    //   email: 'Invalid email address.',
    //   password: 'Invalid password.',
    // });}
        if (user) {
          // User is signed in, get the ID token
          const idToken = await user.getIdToken();

          // Set the ID token in local storage
          localStorage.setItem('userToken', idToken);
          localStorage.setItem('rememberMe', rememberMe.toString());

          console.log('User created successfully');
          navigate('/dashboard');
        }
      });
    } catch (error) {
      // Handle the specific error
      if (error.code === 'auth/email-already-in-use') {
        setError({
          email: 'Email address is already in use. Please use a different email or reset your password.',
        });
      } else if (error.code === 'auth/invalid-credential') {
        setError({
          email: 'Invalid email .',
          password: 'Invalid  password .',
        });
      } else {
        setError({
          email: (email && !emailRegex.test(email)) ? 'Invalid email address. Please enter a valid email.' : (email ? '' : 'Email cannot be empty.'),
          password: (password && !strongPasswordRegex.test(password)) ? 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit.' : (password ? '' : 'Password cannot be empty.'),
        });
      }
      console.error('Error creating user:', error);
      setShowAlert(true);

      setTimeout(() => {
        setError({
          ...error,
          username: '',
          email: '',
          password: '',
        });
      }, 3000);
    }
  };

  const signInWithGoogle = async (e) => {
    e.preventDefault();
  
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const db = getFirestore();

      // Check if the user is already in Firestore
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        // If the user is not in Firestore, create a new user document
        const userDataToStore = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          // Add other properties if needed
        };
  
        await setDoc(userRef, userDataToStore);
  
        console.log('New user data stored in Firestore:', userDataToStore);
      } else {
        console.log('User already exists in Firestore:', userDoc.data());
      }
  
      // Set the ID token in local storage
      const idToken = await user.getIdToken();
      localStorage.setItem('userToken', idToken);
  
      console.log('User signed in successfully');
      // Navigate to the dashboard after successful sign-in
      navigate('/dashboard');
    } catch (error) {
      // Handle the specific error
      console.error('Error signing in with Google:', error);
    }
  };
  useEffect(() => {
    // Check local storage for remember me preference
    const storedRememberMe = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(storedRememberMe);
  }, []);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <GuestLayout>

      
      <div className="grid grid-cols-2 h-full">
        <div className="lg:col-span-1 col-span-2 bg-purple-400  items-center justify-center hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex w-full h-full">
          <div className="w-full h-full flex justify-center items-center bg-cover bg-center bg-gradient-to-b from-violet-600 to-purple-400" style={{ backgroundImage: `url(${back})` }}>
            <div className="">
              <img className='w-40 h-50' src={Socialify} alt='img' />
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-2 flex justify-center items-center px-5 md:px-0">
          <div className='w-[347px] h-[455px] mx-auto '>
            <h1 className="login text-2xl font-semibold   leading-relaxed mb-2">Log in</h1>
            <p className='text-stone-500 text-sm font-normal  leading-none'>Enter Your Email and Password to Sign in</p>
            <form action="#" method="POST">
              <div className="mb-3 mt-3">
                <label htmlFor="username" className="text-center text-neutral-700 text-xs font-bold font-Roboto leading-[18px]">Email Address</label>
                <input type="email" onChange={(e) => setEmail(e.target.value)} id="username" name="username" className="w-full border border-gray-300 text-stone-500 text-xs font-normal font-'Roboto' leading-[18px] rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500" placeholder='Email'/>
                {error.email && <span className="text-red-500 text-xs">{error.email}</span>}

              </div>
              <div className="mb-3 relative">
                <label htmlFor="password" className="text-center text-neutral-700 text-xs font-bold font-Roboto leading-[18px]">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    className="w-full text-stone-500 text-xs font-normal font-Roboto leading-[18px] border border-gray-300 rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500"
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                  {error.password && <span className="text-red-500 text-xs">{error.password}</span>}

                  <div className="mb-4 flex justify-between">
                    <div>
                      <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id="remember" name="remember" className="text-blue-500 " />
                      <label htmlFor="remember" className="text-gray-600 ml-2 mt-1 text-xs font-medium">Remember Me</label>
                    </div>
                    <div className='mt-1'>
                      <Link to='/forgetpassworn'>
                        <p className="hover:underline ms-10 text-purple-800 text-xs font-medium">Forgot Password?</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={handleLogin} type="submit" className="bg-purple-400 hover:bg-purple-500 text-base text-white font-medium rounded-sm py-2 px-4 w-full login-button mb-3">Sign in</button>
              <p className="flex items-center justify-center text-base font-medium mb-3">Or</p>
              <div className="mb-3 hover:bg-purple-100">
                <button onClick={signInWithGoogle} className="flex flex-wrap text-blue-950 justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-sm">
                  <img className="w-5 mr-2" src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" />
                  Google
                </button>
              </div>
              <hr className='my-4' />
            </form>
            <div className="text-end gap-2">
              <p className="text-xs text-gray-400 font-semibold ">
                No account yet?
                <Link to={'/signup'}>
                  <span className='text-xs fw-bold text-purple-700 cursor-pointer ms-1'>Sign Up</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

    </GuestLayout>
  )
}

export default Login