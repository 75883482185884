import React from 'react';
import { notification } from '../component/Inputfielddata';
import { useState } from 'react';
import Navbar from '../component/Navbar';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';


const Notificationmap = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  // Toggle the notification state when the notification area is clicked
  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  // Assuming you have an array of notifications called "notification"

  return (
    <AuthenticatedLayout>
      <Navbar heading="Notifications" showbuton={true} />
      <div className='container-fluid mb-5 mt-4'>
        <div className='row'>
          {notification.map((item, index) => (
            <div className='flex ' onClick={toggleNotification} key={index}>
              <Notifications
                imag={item.image}
                head={item.heading}
                parag={item.paragraph}
                minu={item.time}
                timel={item.timelimit}
              />
            </div>
          ))}
        </div>
      </div>
    </AuthenticatedLayout>

  );
}

const Notifications = ({ imag, head, parag, minu, timel }) => {
  return (
    <>
      <div className="lg:w-[687px] h-fit lg:h-[79px] bg-white rounded p-3 mt-2 mx-auto my-auto flex items-center pb-2 py-3">
        <div className="mr-6 lg:mr-0">
          <img className="lg:w-[50px] lg:h-[50px] me-4" src={imag} alt="img" />
        </div>
        <div className="col-span-2 ">
          <div className='justify-between'>
            <h1 className='text-violet-950 text-xs lg:text-base font-medium '>{head}</h1>
            <p className='text-neutral-600 text-[9px] lg:text-[10px] '>{parag}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-violet-600 text-[10px] font-normal'>{minu}</p>
            <p className='text-violet-600 text-[10px] font-normal'>{timel}</p>
          </div>

        </div>
      </div>


    </>
  );
};

export default Notificationmap;
