import React, { useState } from 'react'
import Navbar from '../component/Navbar'
import axios from 'axios';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
const AIContent = () => {
  const [userInput, setUserInput] = useState('');
  const API_KEY = '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';
  const API_ENDPOINT = 'https://api.openai.com/v1/engines/davinci/completions';
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };
  const generateText = async () => {
    if (isLoading) {
      return; // Prevent multiple requests while loading
    }

    setIsLoading(true);

    const requestBody = {
      prompt: userInput,
      max_tokens: 100,  // Adjust this as needed
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${API_KEY}`,
    };

    try {
      const response = await axios.post(API_ENDPOINT, requestBody, { headers });
      const generatedText = response.data.choices[0].text;
      // Handle and display the generated text.
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthenticatedLayout>
      <Navbar heading="AI Content Generator" showbuton={true} />
      <div className="w-full lg:w-[968px] h-fit bg-white mx-auto">
        <div className="w-full lg:w-[967.95px] h-[65.41px] bg-violet-100 rounded-[5px] shadow mx-auto flex">
          <div className="w-[38px] h-[38px] bg-blue-950 rounded-full mt-3 ms-5" />
          <div className="text-violet-950 text-base font-bold font-['Roboto'] leading-snug mt-3 ms-5">Lorum Ipsum alternative</div>
        </div>
        
        <div className="w-full lg:w-[612px] p-3 lg:p-0 text-neutral-600 text-base font-normal mt-5 leading-snug mx-auto">
        Lorum Ipsum" (often written as "Lorem Ipsum") is a placeholder text commonly used in the printing and typesetting industry when the actual content is not available. It's used to simulate the appearance of real text without focusing on the content itself. If you're looking for alternatives to Lorem Ipsum, here are a few options
        </div>
        <div className="w-full lg:w-[612px] p-3 lg:p-0 mx-auto mt-5 text-neutral-600 text-base font-normal leading-snug">
        Office Ipsum: Office Ipsum generates text with jargon and buzzwords often heard in a corporate or office setting. (Website: officeipsum.com)<br/>Pirate Ipsum: For a playful and pirate-themed twist on Lorem Ipsum, Pirate Ipsum generates placeholder text with a nautical flair. (Website: pirateipsum.me)<br/>Samuel L. Ipsum: If you're looking for something with a bit more attitude, this generator uses phrases and quotes from actor Samuel L. Jackson's movies. (Website: slipsum.com)<br/>Veggie Ipsum: This generator uses vegetable-related words and phrases, making it suitable for projects related to health, food, or vegetarianism. (Website: veggieipsum.com)

        </div>

      </div>

      <div className="relative flex w-full lg:w-[847.10px] h-[65.41px] mb-4 mx-auto mt-4">
        <input value={userInput}
          onChange={handleInputChange} type="text" className="w-full py-2 pl-3 pr-3 bg-violet-100 rounded-[10px] shadow placeholder-violet-950" placeholder="Send A Message" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button onClick={generateText} className="absolute top-0 right-0 h-full px-3 py-2 text-violet-950 bg-transparent  rounded-md" type="button" >
        {isLoading ? 'Loading...' : 'Send'}</button>
      </div>



    </AuthenticatedLayout>
  )
}

export default AIContent