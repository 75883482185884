import tagicon from '../images/tag.svg';
import eyeicon from '../images/eye.svg';
import diamondicon from '../images/diamond.svg';


const LandingpageData = [
    {
        img:tagicon,
        para1:"We create & plan a customized content strategy for your blog",
        para2:"Get paid by your listeners, every month, predictably. No CPMs, and no scheduling required."
    },
    {
        img:diamondicon,
        para1:"We write articles that blend content with optimization",
        para2:"Get paid by your listeners, every month, predictably. No CPMs, and no scheduling required."
    },
    {
        img:eyeicon,
        para1:"We promote your blog posts so they can get backlinks",
        para2:"Get paid by your listeners, every month, predictably. No CPMs, and no scheduling required."
    }
]
export default LandingpageData