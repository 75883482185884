import React from 'react'

const Button = ({val,className,onClick,type}) => {
  return (
    <>
    <button type={type} className={className}>{val}</button>
    </>
  )
}

export default Button