import React from 'react'

export default function GuestLayout({children}) {
  return (
    <>
    <div className="container-fluid h-screen p-0">
    {children}
    </div>
    
    
    </>
  )
}
