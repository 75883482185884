import React, { useState } from 'react';

const AddressDropdown = ({ onSelectAddress,selectedAddress }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleAddressClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelectAddress = (address) => {
    onSelectAddress(address);
    setShowDropdown(false);
  };
  const addresses = [
    { id: 1, name: 'Address 1' },
    { id: 2, name: 'Address 2' },
    { id: 3, name: 'Address 3' },
    // Add more addresses as needed
  ];

  return (
    <div className="relative">
      <div className="input-group">
        <input
          type="text"
          className="w-full lg:w-[440] h-[43px]  border border-zinc-300 mt-2 pl-3"
          placeholder="Select or Enter Address"
          defaultValue={selectedAddress ? selectedAddress.name : ''}  
          readOnly      />
        <span onClick={handleAddressClick} className="absolute cursor-pointer right-[20px] top-[34px] transform -translate-y-1/2">
          {/* You can replace the arrow character with an actual arrow icon */}
          &#9660;
        </span>
      </div>
      {showDropdown && (
        <ul className="absolute left-0 mt-2 border border-zinc-300 bg-white w-[440px] max-h-[200px] overflow-y-auto rounded-[5px]">
          {addresses.map((address) => (
            <li
              key={address.id}
              onClick={() => handleSelectAddress(address)}
              className="px-3 py-2 cursor-pointer hover:bg-gray-100"
            >
              {address.name}
              
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressDropdown;
