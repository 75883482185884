import React from 'react'
import Logo from '../images/newsocillogo.svg';
import Button from '../component/Button';
import ladingroundpic from '../images/roundimage.svg'
import Testimonials from './Testimonials';
import FAQs from './FAQ';
import LandingpageHeader from './LandingpageHeader';
import Herosection from './Herosection';
import Featurelanding from './Featurelanding';
import Statslandingpage from './Statslandingpage';
import Chartlandingpage from './Chartlandingpage';
import GuestLayout from '../Layouts/GuestLayout';
import interestbackground from '../images/interestbackgrou.png'
import Pricinglanding from './Pricinglanding';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import statsbackground from '../images/statbackground.png'
import thread from '../images/Threads.svg'
import instagram from  '../images/instagram-167_svgrepo.com.svg'
import facebook from  '../images/facebook-176_svgrepo.com.svg'
import twitter from  '../images/twitter-176_svgrepo.com.svg'
import linkedin from  '../images/linkedin-161_svgrepo.com.svg'
import youtube from '../images/youtube-168_svgrepo.com.svg'



const Landingpage = () => {

    return (
        <GuestLayout>

            <div className="w-full p-0 bg-white">
                
                <div className=''>
                <LandingpageHeader />
                </div>
                <Herosection />
                <Element name='features'>
                    <Featurelanding />
                </Element>

                <Statslandingpage />
                <Chartlandingpage />

                <div className="lg:h-[600px] lg:flex lg:p-5 mt-4 w-full mb-5" style={{
                    backgroundImage: `url(${statsbackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "18% ",
                    backgroundPosition: "right 10% top 20%",
                    backgroundPosition: "right bottom"
                }}>
                    <div className="w-full lg:w-1/2 ms-1">
                        <img className='w-full h-full ' src={ladingroundpic} />

                    </div>
                    <div className="w-full lg:w-1/2 md:py-40 px-4">
                        <h1 className="lg:w-[587px] text-violet-950 lg:text-[40px] md:pb-5 font-bold  md:leading-[46.80px]">Promote your blog posts so they can get backlinks </h1>
                        <div className="lg:w-[445px] text-zinc-900 text-base lg:text-lg font-normal  leading-loose">Subscribe to our application and get deals from top notch companies in the world every month. Be an exclusive member of the team.</div>
                    </div>
                </div>
                <Element name="pricing">
                    <Pricinglanding />
                </Element>

                <div className='lg:h-[700px] md:py-20' >
                    <h1 className='text-center text-violet-950 text-2xl lg:text-[50px]  lg:font-bold mt-5'>Testimonials</h1>
                    <div className="md:my-[100px] ">
                        <Testimonials />
                    </div>
                </div>
                <Element name='faqs'>
                    <FAQs />
                </Element>
                <div className="w-full lg:h-[460px] bg-stone-50 sm:py-4 lg:py-[100px] flex flex-col items-center justify-center" style={{ backgroundImage: `url(${interestbackground})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    <h2 className="w-full lg:w-[612px] h-[83px] mx-auto px-4 text-center text-violet-950 lg:text-[38px] font-bold md:mt-1 mt-4 capitalize md:leading-[52.71px] tracking-tight">Interested in growing your online presence?</h2>
                    <Link to='/login'>
                        <Button className="w-[120px] lg:font-semibold text-xs lg:text-lg  lg:w-[242px] h-[34px] lg:h-[70px] bg-violet-600 hover:bg-violet-800 text-white mb-5 lg:mt-[50px] rounded-md" val='Lets Start' />
                    </Link>
                </div>
                 <center className='bg-gray-100 py-5'>
                 <img className="mt-3 w-[100px] h-[25px]" src={Logo} alt="Logo" />
                 <Link to='/terms_condition'>
                 <button className='cursor-pointer text-purple-400'>Terms And Conditions</button>
                 </Link><br/>
                 <Link to='/privacy_policy'>
                 <button className='cursor-pointer text-purple-400'>Privacy Policy</button>
                 </Link>
                 <ul className='flex items-center justify-center gap-2 py-3'>
                    <li><a href='https:www.threads.net'><img className='h-4 w-4' src={thread}/></a></li>
                    <li><a href='https:www.instagram.com'><img className='h-4 w-4' src={instagram}/></a></li>
                    <li><a href='https:www.facebook.com'><img className='h-4 w-4' src={facebook}/></a></li>
                    <li><a href='https:www.twitter.com'><img className='h-4 w-4' src={twitter}/></a></li>
                    <li><a href='https:www.linkedin.com'><img className='h-4 w-4' src={linkedin}/></a></li>
                    <li><a href='https:www.youtube.com'><img className='h-4 w-4' src={youtube}/></a></li>
                 </ul>
                 </center>
            </div>

        </GuestLayout>
    )
}

export default Landingpage