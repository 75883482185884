import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "../component/Card";
import { landingpagetestimonial } from "../component/insta";



const Testimonial= () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    
    responsive: [
      {
        breakpoint: 992, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="my-5" style={{ width: '100%', margin: '0 auto' }}>
      <Slider {...settings}>
        {landingpagetestimonial.map((card, index) => (
          <div className="ms-4 py-3  rounded-5" key={index} style={{ width: '300px', margin: '0 10px' }}>
            {/* Adjust the margin value to control the space between cards */}
            <Card title={card.heading} detail={card.paragraph} icon={card.img} subhead={card.subhead} para={card.subpara} />
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Testimonial;
