import React, { useState } from 'react'
import Navbar from '../component/Navbar'
import Input from '../component/Input'
import Button from '../component/Button'
import CreateSocialCom from '../component/CreateSocialCom'
import { data2 } from '../component/insta';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout'
import postimage from '../images/imagepost.png';


const CreateImagePost = () => {
  const [showdiv, setshowdiv] = useState(false);
  const handleClick = () => {
    setshowdiv(!showdiv)
  }

  return (
    <AuthenticatedLayout>
      <Navbar heading={`Create Image Post`} showbuton={true} />
      <div className='flex justify-center w-full '>
        <div className="my-4 w-full lg:w-[635px] h-full bg-white rounded-[10px] shadow border pb-5">
          <div className='px-14  pt-5'>
            <h1 className="text-[#290064] lg:text-xl font-semibold leading-110 bg-clip-text">Create Image Post</h1>
            <p className="text-neutral-600 text-sm lg:text-base font-normal leading-snug mb-4">Here You Can Choose Posting Type to Post in Your Social Media</p>
            <h6 className="text-violet-950 text-[10px] font-normal text-xs leading-[11px] mb-1">Post Name</h6>
            <Input placeholder="Post Name [Optional]" className="font-normal w-full me-5 lg:w-[514px] h-[43px] lg:h-[52px] rounded-[5px] border text-base border-zinc-300 ps-4" />

            <h6 className="text-violet-950 text-[10px] font-normal text-xs leading-[11px] mt-4 mb-1">Post Caption</h6>
            <textarea placeholder='Post Caption' className="w-full lg:w-[514px] h-[160px] lg:h-[247px] rounded-[5px] border border-zinc-300 mb-4 ps-4" />
            <div className='flex sm:justify-center lg:justify-around w-full lg:flex-row flex-col md:flex-row gap-2'>
              <Button className="w-[175px] lg:w-[219px] whitespace-nowrap lg:mb-0 mb-2  h-[63px]  bg-white border-1 border-purple-400 text-purple-500 rounded-[15px]" val='Upload Your Image' />
              <img className='w-44 h-28' src={postimage} alt='img' />
            </div>
            <div className="text-violet-950 text-[10px] font-normal text-xs leading-[11px] mb-2">Posting Time</div>
            <span className='flex mb-5'>
              <label class="relative inline-flex items-center cursor-pointer">
              <input onChange={handleClick} type="checkbox" value="" className="sr-only peer bg-purple-500" />
                <div class=" me-3 w-[47px] h-6 bg-purple-300 rounded-[10px] shadow border border-violet-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-violet-600 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-white"></div>
              </label>

              <Button className="text-violet-950 text-[10px] font-normal leading-[11px] hover:text-blue-900 hover:font-extralight" val='Post Now' />
            </span>
            {showdiv && (
              <div className="divToShow">
                <p className="text-blue-950 text-[10px] font-normal">Schedule time</p>
                <Input placeholder="Time" className="w-full mb-2 px-3 lg:w-[514px] h-[52px] rounded-[5px] border border-zinc-300" />
                <p className="text-blue-950 text-[10px]  font-normal">Time Zome</p>
                <Input placeholder="Time Zone" className="w-full px-3 mb-2 lg:w-[514px] h-[52px] rounded-[5px] border border-zinc-300" />
                <p className="text-blue-950 text-[10px] font-normal">Time Interval</p>
                <Input placeholder="Time Interval" className="w-full mb-2 px-3 lg:w-[514px] h-[52px] rounded-[5px] border border-zinc-300" />
              </div>
            )}
            <h2 className="text-violet-950 lg:text-2xl font-bold ">Select Platforms</h2>
            <p className="text-neutral-600 text-sm lg:text-base py-2 font-normal  leading-snug">Select Platforms where You wanna Post.</p>
            <div className='row   flex justify-center items-center' >
              {data2.map((social, index) => (
                <div key={index} className='w-[98px] md:w-1/4  lg:h-[141.57px] rounded-2 mx-2 text-sm bg-white  shadow border border-zinc-200 mb-4'>
                  <CreateSocialCom key={index} img={social.image} text={social.para} showCheckbox={true} path={social.path} className={'h-7'} />
                </div>
              ))}
            </div>
            <span className='flex mx-5'>
              <Button className="w-full lg:w-[219px] text-xs lg:text-base p-2 lg:h-[63px] whitespace-nowrap text-white bg-gradient-to-r bg-violet-600 hover:bg-violet-900 rounded-[15px] me-4" val='Publish Now' />
              <Button className="w-full lg:w-[219px] text-xs lg:text-base p-2  lg:h-[63px] whitespace-nowrap  border-1 border-purple-400 hover:bg-violet-800 hover:text-white text-purple-500 rounded-[15px]" val='Cancel' />
            </span>
          </div>
        </div>
      </div>
    </AuthenticatedLayout>
  )
}

export default CreateImagePost