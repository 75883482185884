import React, { useState } from 'react';
import PurchaseModal from './PurchaseModal';

const Subscription = ({ head, facili1, facili2, facili3, facili4, facili5, facili6, facili7, facili8, icon, subhead, onclick, button,payment }) => {

 
  

  return (
    <>
      <div className='container-fluid '>
        <div className='row p-0'>
          <div className="flex bg-[#F9F7FF] flex-col mt-4 p-6 justify-center w-70 h-70 text-center   rounded-lg border border-gray-100 shadow mb-5 ">
            <h3 className="mb-2 lg:text-4xl font-semibold flex justify-center md:justify-start ms-2 text-purple-950">{head}</h3>
            <ul role="list" className="mb-4 space-y-2 flex-0 ">
              <li className="flex items-center justify-center md:justify-start space-x-3  lg:ml-4">
                {/* Icon */}
                <svg className="flex-shrink-0 w-4 h-4 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili1}</span>
              </li>
              <li className="flex items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                {/* Icon */}
                <svg className="flex-shrink-0 w-4 h-4 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili2}</span>
              </li>
              <li className="flex items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                <svg className="flex-shrink-0 w-4 h-4 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili3}</span>
              </li>
              <li className="flex items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M14 3.95117L16.0079 6.06174L12.1188 9.95082L16.0079 13.8399L13.8866 15.9612L9.9975 12.0721L6.11796 15.9517L3.99664 13.8304L7.87618 9.95082L3.98709 6.06174L6.10841 3.94042L9.9975 7.8295L14 3.95117Z" fill="#FF0000" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili4}</span>
              </li>
              <li className="flex items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M14 3.95117L16.0079 6.06174L12.1188 9.95082L16.0079 13.8399L13.8866 15.9612L9.9975 12.0721L6.11796 15.9517L3.99664 13.8304L7.87618 9.95082L3.98709 6.06174L6.10841 3.94042L9.9975 7.8295L14 3.95117Z" fill="#FF0000" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili5}</span>
              </li>
              <li className="flex items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                <svg className="flex-shrink-0 w-4 h-4 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili6}</span>
              </li>
              <li className="flex items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                <svg className="flex-shrink-0 w-4 h-4 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili7}</span>
              </li>
              <li className="flex  items-center space-x-3  lg:ml-4 justify-center md:justify-start">
                <svg className="flex-shrink-0 w-4 h-4 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span className='text-[#001D6C] text-sm lg:text-base'>{facili8}</span>
              </li>
            </ul>
            <div className=" lg:text-4xl font-semibold text-purple-950">{subhead}</div>
            <div className="text-[13px]  dark:text-gray-400 text-[#290064]">per month</div>
            <button onClick={payment} className="mt-4 font-medium w-full lg:w-[154px] mx-auto rounded-lg text-sm px-5 py-2.5 whitespace-nowrap text-center text-white bg-violet-600 hover:bg-violet-900">
              {button}
            </button>
          </div>
          
        </div>
      </div>




    </>
  )
}

export default Subscription