import React, { useRef, useState } from 'react'
import Navbar from '../component/Navbar'
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout'
import rowsData from '../component/TableRowdata'
import TableRow from '../component/TableRow'
import image from '../images/search.svg'
import DisconnectModal from '../component/DisconnectModal'
import ReferalModal from '../component/ReferalModal'


const Referals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const inputRef = useRef(null);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    console.log('close')
  };
  const copyToClipboard = () => {
    console.log('Copying to clipboard...');
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    // Select the text field
    try {
      // Select the text field
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field using the Clipboard API
      document.execCommand('copy');

      // Deselect the text field
      inputRef.current.blur();
      setInputValue('');


      // Optional: Show a success message or perform other actions after copying
      console.log('Text copied to clipboard!');
    } catch (err) {
      // Handle errors, e.g., if the browser doesn't support the Clipboard API
      console.error('Unable to copy text to clipboard:', err);
    }
  };
  return (
    <AuthenticatedLayout>
      <Navbar heading="Referral" showbuton={true} />
      {showAlert && (
        <div className="alert fixed top-15 -right-0">
          <div class="flex justify-end items-center p-4 w-52 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
            <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div>
              <span class="font-medium">Copy to Clipboard!</span>
            </div>
          </div>
        </div>
      )}
    <div className='w-full px-3'>
      <div className='w-full lg:w-[930px] mx-auto h-[250px] mt-5 bg-white mb-10 rounded-3 pt-4 flex flex-col justify-center items-center'>
        <div className="flex text-violet-400 px-5 text-lg lg:text-[30px] font-bold font-['Inter'] whitespace-nowrap">Refer Friends and Get Reward</div>
        <div className=" mb-3 mt-4 flex w-full px-3 ">
          <input ref={inputRef} type="url" value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="lg:w-[862px] md:w-[700px] w-full px-2 h-12  border border-purple-100 focus:border-transparent focus:outline-none focus:shadow-none" placeholder="http://www.figma.com/file/AYUwq36NKyub7IOPunhjdr5yup...." aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <button onClick={copyToClipboard} className="bg-purple-400 h-12  rounded-1 w-[160px] md:w-[210px] lg:w-[210px] p-1 lg:py-2 text-center whitespace-nowrap text-white text-sm md:text-lg font-semibold font-'Inter'" type="submit" style={{ background: "linear-gradient(82deg, #AD79F8 2.25%, #8739FB 79.87%)" }}>Copy Link</button>
        </div>
      </div>
      <div className='w-full lg:w-[930px] mx-auto h-[507px] overflow-auto bg-white mb-10 rounded-3 px-3 scroll2'>
        <div className='flex justify-end my-3'>
          <div className="lg:w-[290px] h-12 px-3 py-3 bg-neutral-100 bg-opacity-60 rounded-2xl justify-start items-center gap-2 inline-flex">
           <img src={image}/>
            <input type='search' placeholder='Search' className=" outline-none w-full lg:w-[200px] bg-neutral-100 mx-2 text-lg font-medium " />
          </div>
        </div>

        <table className='table mx-4 '>
          <thead>
            <tr>
              <th className='text-stone-500 text-sm font-semibold py-4'>Name</th>
              <th className='text-stone-500 text-sm font-semibold py-4'>Date/time</th>
              <th className='text-stone-500 text-sm font-semibold py-4'>Reward</th>
              <th className='text-stone-500 text-sm font-semibold py-4'>Status</th>
              <th className='text-stone-500 text-sm font-semibold py-4 whitespace-nowrap'>View Reciept</th>
            </tr>
          </thead>
          <tbody >
            {rowsData.map((x, index) => (
              <TableRow
                key={index}
                name={x.name}
                imageSrc={x.imageSrc}
                date={x.date}
                time={x.time}
                signalCount={x.signalCount}
                status={x.status}
                onclick={handleButtonClick}
              />
            ))}


          </tbody>
        </table>
      </div>
      </div>
      {isModalOpen && <ReferalModal closeModal={handleCloseModal} />}
    </AuthenticatedLayout>
  )
}

export default Referals