import React, { useState, useEffect } from 'react';
import Input from '../component/Input';
import Button from '../component/Button';
import FacebLogin from '../component/FacebLogin';

const CreateSocialCom = ({ checked, img, text, para, head, showCheckbox, className, showmodal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
 
  return (
    <>
      <div className='flex justify-center ' onClick={openModal}>

        {showCheckbox && (
          <input type='checkbox' checked={checked} className="w-[23px]  mt-3 h-3 md:-ms-3  md:h-5 accent-purple-500 " />
        )}
        <div className='mt-5 items-center'>
          <img className='w-10 h-10 ' src={img} alt='img' />
          <p className={className}>{text}</p>
        </div>
      </div>

      {showmodal && (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          {isModalOpen && (
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              {/* Background backdrop */}
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">

                <div className="flex min-h-full  p-4 text-start  sm:items-center sm:p-0">
                  <div className="w-full sm:w-[486px] bg-white rounded-[10px] shadow border border-zinc-200 mx-auto px-5 py-4 sm:p-0">
                    <div className="d-flex bd-highlight">
                      <div className="me-auto p-2 bd-highlight">
                        {/* Your content goes here */}
                      </div>
                      <div className="p-2 bd-highlight">
                        <button className='text-purple-500 ' onClick={(e) => (closeModal())}>&#10008;</button>
                      </div>
                    </div>

                    <div className="flex flex-col justify-center items-start sm:items-start">

                      <div className="mt-4 mb-4">

                        <div className='flex justify-between mt-2'>
                          <p className="w-[253px] h-[30px] text-blue-950 text-[10px] mb-2  leading-[14px]">By Clicking this Log In Button You will Redirected to  Your {para} account.</p>
                        </div>


                        <button className='w-[262px] h-[43px] bg-white rounded-[5px] shadow'>
                          <span className="flex items-center px-3">
                            <img className="w-8 h-8 mr-2" src={img} alt={head} />
                            <p className="text-[#001D6C] text-base font-medium ms-2 leading-7" >
                              Login with {para}
                            </p>
                          </span>
                        </button>
                        <FacebLogin />

                        <p className="text-violet-600 text-base font-medium  leading-7 mt-3 mb-3">
                          Or
                        </p>
                        <p className="text-blue-950 text-[10px]   leading-[14px] mb-3">
                          If you want to access the account manually.
                        </p>
                        <p className="text-violet-950 text-[10px] font-normal mb-1  leading-[14px]">
                          Email/Username
                        </p>
                        <Input
                          type="text"
                          className="w-full lg:w-[400px] h-[43px] border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 mb-4"
                          placeholder="Email/Username"
                        />
                        <p className="text-violet-950 text-[10px] font-normal mb-1  leading-[14px]">
                          Password
                        </p>
                        <Input
                          type="password" // Change type to 'password' for password input
                          className="w-full lg:w-[400px] h-[43px] border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500 mb-4"
                          placeholder="Password"
                        />
                        <br />
                        <Button
                          className="w-full lg:w-[400px] h-[43px] bg-violet-600 hover:bg-violet-900 rounded-[5px] text-white"
                          val="Create Your Account" type="submit"
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}


    </>
  );
};

export default CreateSocialCom;
