import React from 'react'
import Stats from '../component/Stats';
import { data1 } from '../component/SocialData';
import statsbackground from '../images/statbackground.png'
const Statslandingpage = () => {
    return (
        <div className="lg:h-[472px] lg:flex  mt-5 lg:ms-[100px]" style={{ backgroundImage: `url(${statsbackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "18% ",
        backgroundPosition: "right 10% top 20%",
        backgroundPosition: "right bottom"}}>
            <div className="w-full lg:w-1/2 ">
                <div className='flex flex-wrap my-5 p-0  lg:me-28 '>
                    {data1.map((item, index) => (
                        <div key={index} className=' mt-2 w-[160px] md:w-1/2 -rotate-[13deg] '>
                            <Stats title={item.title} icon={item.icon} shareQuantity={item.shareQuantity} percentage={item.percentage} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full lg:w-1/2 md:py-20 px-4 ">
                <div className="lg:w-[512px] lg:h-[140px]  text-violet-950 lg:text-4xl font-bold  ">Create & plan a customized content strategy for your blog</div>
                <div className="lg:w-[445px] text-zinc-900 text-base lg:text-lg font-normal  leading-loose">List a product once and it will be published across all of your connected sales channels. Similarly, when you make changes to your listing</div>
            </div>
        </div>
    )
}

export default Statslandingpage