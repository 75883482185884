
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa'; 
import backgroungimg from '../images/faqback.png'

const faqs = [
  { question: 'How long until we deliver your first blog post?', answer: 'It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we MUST do before writing your first blog post.' },
  { question: 'How long until we deliver your first blog post?', answer: 'That includes the in-depth research & creation of your monthly content marketing strategy' },
  { question: 'What is JSX?', answer: 'JSX is a syntax extension for JavaScript, recommended to use with React to describe what the UI should look like. JavaScript, recommended to use with React to describe what the UI should look like. JavaScript, recommended to use with React to describe what the UI should look like.' },
  { question: 'What is a functional component?', answer: 'A functional component is a simpler way to write components in React, using only functions.' },
  { question: 'What is the Virtual DOM?', answer: 'The Virtual DOM is a lightweight copy of the real DOM in memory, which React keeps in sync with the real DOM.' },
];

// FAQ component
const FAQ = () => {
  const [expanded, setExpanded] = useState(Array(faqs.length).fill(false));

  const toggleFAQ = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  return (
    <div
        className="lg:h-[800px] w-full py-1 lg:py-4 my-4"
        style={{
          backgroundImage: `url(${backgroungimg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
    <h1 className="text-center pb-5 text-violet-950 text-2xl mt-5  lg:text-[50px] lg:font-bold md:my-20">
         Frequently Ask Questions
      </h1>
      <div className='grid grid-cols-1 lg:grid-cols-2 -px-5 mx-4 md:mx-1'>
  <div>
    {faqs.slice(0, 2).map((faq, index) => (
      <div key={index} onClick={() => toggleFAQ(index)} className="lg:w-[558px]  h-fit p-3 bg-white rounded mx-auto mb-4 shadow border border-violet-50" >
        <div className='flex p-3'>
          {expanded[index] ? (
            <FaMinus className='cursor-pointer space-x-1 text-red-500 mt-[7px]' />
          ) : (
            <FaPlus className='cursor-pointer mt-[7px]' />
          )}
          <h3 className='ms-3 text-slate-900 lg:text-xl font-semibold leading-normal'>{faq.question}</h3>
        </div>
        {expanded[index] && <p className='ms-5'>{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div>
    {faqs.slice(2, 5).map((faq, index) => (
      <div key={index} onClick={() => toggleFAQ(index + 2)} className="lg:w-[558px] h-fit p-3 bg-white rounded mx-auto mb-4 shadow border border-violet-50" style={{ marginBottom: '10px' }}>
        <div className='flex p-3'>
          {expanded[index + 2] ? (
            <FaMinus className='cursor-pointer text-red-500 mt-[7px]' />
          ) : (
            <FaPlus className='cursor-pointer mt-[7px]' />
          )}
          <h3 className='ms-3 text-slate-900 lg:text-xl font-semibold leading-normal'>{faq.question}</h3>
        </div>
        {expanded[index + 2] && <p className='ms-5'>{faq.answer}</p>}
      </div>
    ))}
  </div>
</div>
</div>
  );
};

export default FAQ;
