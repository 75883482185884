import React from 'react'
import Navbar from '../component/Navbar'
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout'

const AIPicture = () => {
  return (
    <AuthenticatedLayout>
    <Navbar heading="AI Picture Generator" showbuton={true} />
      <div className="w-full lg:w-[968px] h-96 bg-white mx-auto">
        <div className="w-full lg:w-[967.95px] h-[65.41px] bg-violet-100 rounded-[5px] shadow mx-auto flex">
          <div className="w-[38px] h-[38px] bg-blue-950 rounded-full mt-3 ms-5" />
          <div className="text-violet-950 text-base font-bold font-['Roboto'] leading-snug mt-3 ms-5">Lorum Ipsum alternative</div>
        </div>
        
      </div>
      <div className=" mx-auto mt-3 w-full lg:w-[958.87px] h-[65.41px] bg-violet-100 rounded-[10px] shadow" >
      <input type="text" className="w-full h-[65px] py-2 pl-3 pr-3 bg-violet-100 rounded-[10px] shadow placeholder-violet-950" placeholder="What will you make today? Just type" aria-label="Recipient's username" aria-describedby="button-addon2" />
      </div>
      <div className="relative flex w-full lg:w-[847.10px] h-[65.41px] mb-4 mx-auto mt-4">
        <input type="text" className="w-full py-2 pl-3 pr-3 bg-violet-100 rounded-[10px] shadow placeholder-violet-950" placeholder="Send A Message" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="absolute top-0 right-0 h-full px-3 py-2 text-violet-950 bg-transparent  rounded-md" type="button" >Send</button>
      </div>

  </AuthenticatedLayout>
  )
}

export default AIPicture