import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Subscription from '../pages/Subscription';
import subscriptiondata from '../component/Subscriptiondata';

const Pricinglanding = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null, // Set to null to hide the next arrow
    prevArrow: null, // Set to null to hide the previous arrow
    responsive: [
      {
        breakpoint: 992, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect will only run once on mount

  return (
    <div className=' bg-gradient-to-r from-slate-200 to-transparent via-gray-50 py-20 rounded-5'>
    <h1 className=" mx-auto lg:w-[589px]  text-center text-violet-950 lg:text-[54px] font-bold   leading-[59.40px]">Find The Perfect Plan for Your Needs</h1>
    <div className="mx-auto lg:w-[616px] text-center text-zinc-800 text-sm lg:text-lg font-normal pt-4 px-4 md:leading-[25.20px]">Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque</div>

      {isSmallScreen ? (
        <Slider {...settings}>
          {subscriptiondata.map((item, index) => (
            <div key={index} className=' px-4'>
              <Subscription
                head={item.heading}
                facili1={item.facility1}
                facili2={item.facility2}
                facili3={item.facility3}
                facili4={item.facility4}
                facili5={item.facility5}
                facili6={item.facility6}
                facili7={item.facility7}
                facili8={item.facility8}
                subhead={item.subheading}
                button={item.button}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="flex flex-wrap justify-center gap-5">
          {subscriptiondata.map((item, index) => (
            <div key={index} className="w-[260px] md:w-1/4 lg:w-1/5">
              <Subscription
                head={item.heading}
                facili1={item.facility1}
                facili2={item.facility2}
                facili3={item.facility3}
                facili4={item.facility4}
                facili5={item.facility5}
                facili6={item.facility6}
                facili7={item.facility7}
                facili8={item.facility8}
                subhead={item.subheading}
                button={item.button}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Pricinglanding