import React, { useState } from 'react';
import { auth } from '../firebase-config/firebase'; // Import your firebase configuration
import {sendPasswordResetEmail} from 'firebase/auth'
import backgroundimage from '../images/newforgot.png';

const Forgotten = () => {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(null);
  
  const validateEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      setError(null);
      setTimeout(() => {
        setResetSent(false);
        setError(null); // Change from setError(false) to setError(null)
      }, 2000);
    } catch (error) {
      setResetSent(false);
      setError('Please Enter a Valid Email', error);
      setTimeout(() => {
        setError(null); // Change from setError(false) to setError(null)
      }, 3000);
    }
  };
  return (
    <>
      <div className='w-full h-full   bg-gradient-to-b from-violet-600 to-purple-400 forgotten'>
      {resetSent && 
            <p className='alert alert-primary'>Password reset email sent. Check your inbox.</p>}
        <div className='w-full h-full bg-cover  flex justify-center items-center' style={{ backgroundImage: `url(${backgroundimage})` }}>
          <div className="w-[300px] h-auto lg:w-[448px] lg:h-[328px] bg-white p-4 flex-0 justify-center items-center  shadow shadow-slate-300 " >
            <h1 className="login lg:text-2xl font-semibold mb-2 font-roboto leading-110 bg-clip-text">Forgotten Your Password?</h1>
            <p className="text-slate-500 text-sm lg:text-base">There is nothing to worry about; we'll send you a message to help you reset your password.</p>
            <form onSubmit={handleResetPassword} className="my-4">
              <div className="flex flex-col space-y-5">
                <label htmlFor="email">
                  <p className="lg:font-medium lg:text-base text-sm text-slate-700 pb-2">Email address</p>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border border-gray-300 text-stone-500 text-xs font-normal font-'Roboto' leading-[18px] rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500"
                    placeholder='Email'
                  />
                  {error && <p className='text-red-500 text-xs'>{error}</p>}

                </label>
                <button
                  type="submit"
                  className="w-full text-sm lg:text-base py-2 lg:font-medium text-white bg-purple-400 hover:bg-purple-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
                >
                  <span>Send Reset Link</span>
                </button>
              </div>
            </form>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotten;
