import React, { useState, useEffect } from 'react';
import { auth } from '../firebase-config/firebase';
import { confirmPasswordReset } from 'firebase/auth';
import backgroundimage from '../images/newforgot.png';
import { useNavigate } from 'react-router-dom';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'

const ResetPasswLink = () => {
  const [number, setNumber] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(null);


  const navigate = useNavigate();
   

  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const extractedOobCode = urlParams.get('oobCode');
    setOobCode(extractedOobCode);
  }, []);

  const handleConfirmPasswordReset = async (e) => {
    e.preventDefault();

    try {
      await confirmPasswordReset(auth, oobCode, confirmPass);
      setResetSent(true);
      setError(null);
      setTimeout(() => {
        setResetSent(false);
      }, 2000);
      navigate('/login');
    } catch (error) {
      setResetSent(false);
      setError( {
      password1: (!number || !confirmPass) ? 'Please fill in all the fields.' :  (number ? '' : 'Email cannot be empty.'),
      password: (number && !strongPasswordRegex.test(number)) ? 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit.' : (number ? '' : 'Password cannot be empty.'),
      confirmPassword: (!error.fillFields && number !== confirmPass) ? 'Passwords do not match.' :  (confirmPass ? '' : 'Password cannot be empty.'),

      } );
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <>
      <div className='w-full h-full bg-gradient-to-b from-violet-600 to-purple-400 forgotten'>
        {resetSent && <p className='alert alert-primary'>Password Change.</p>}
        <div className='w-full h-full bg-cover flex justify-center items-center' style={{ backgroundImage: `url(${backgroundimage})` }}>
          <div className="w-[300px] h-auto lg:w-[448px] lg:h-[328px] bg-white p-4 flex-0 justify-center items-center shadow shadow-slate-300">
            <h1 className="login lg:text-2xl font-semibold mb-2 font-roboto leading-110 bg-clip-text">Reset Your Password</h1>
            <form onSubmit={handleConfirmPasswordReset} className="my-4">
              <div className="flex flex-col space-y-5">
                <label htmlFor="number">
                  <p className="lg:font-medium lg:text-base text-sm text-slate-700 pb-2">Enter Your Password</p>
                  <div className='relative'>
                  <input
                     onChange={(e) => setNumber(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={number}
                    className="w-full text-stone-500 text-xs font-normal font-Roboto leading-[18px] border border-gray-300 rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500"
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                  </div>
                   
                  {error && error.password && <p className='text-red-500 text-xs'>{error.password}</p>}
                </label>
                <label htmlFor="confirm">
                  <p className="lg:font-medium lg:text-base text-sm text-slate-700 pb-2">Confirm Password</p>
                  <div className='relative'>
                  <input
                    onChange={(e) => setConfirmPass(e.target.value)}
                    type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPass}
                  className="w-full border border-gray-300 text-stone-500 text-xs font-normal font-Roboto leading-[18px] rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500"
                  placeholder='Confirm Password'
                />
                <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                  </div>
                  
                  {error && error.confirmPassword && <p className='text-red-500 text-xs'>{error.confirmPassword}</p>}
                </label>
                <button
                  type="submit"
                  className="w-full text-sm lg:text-base py-2 lg:font-medium text-white bg-purple-400 hover:bg-purple-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
                >
                  <span>Save</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswLink;
