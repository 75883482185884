// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import {getAuth} from 'firebase/auth'
// import { getFirestore } from 'firebase/firestore';
// import {getStorage} from 'firebase/storage'
// const firebaseConfig = {
//   apiKey: "AIzaSyADbvkNuMkTSV8HJPiU4kT876vMRA9bpOA",
//   authDomain: "novabuzzapp.firebaseapp.com",
//   projectId: "novabuzzapp",
//   storageBucket: "novabuzzapp.appspot.com",
//   messagingSenderId: "459261868011",
//   appId: "1:459261868011:web:4918cf01b7990ce99ccbae",
//   measurementId: "G-TJVFL5JXF7"
// };

// const app = initializeApp(firebaseConfig);
// export const storage = getStorage(app)
// export const db = getFirestore(app);
// export const auth = getAuth(app)

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
