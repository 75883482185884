
const subscriptiondata = [
    {
        heading:"Basic",
        icon:'',
        facility1:"Facility No One",
        facility2:"Facility No Two",
        facility3:"Facility No Three",
        facility4:"Facility No Four",
        facility5:"Facility No Five",
        facility6:"Facility No Six",
        facility7:"Facility No Seven",
        facility8:"Facility No Eight",
        subheading:"$10.99",
        para:"Per Month",
        button:"Buy Now"
       
    } ,    
    {
        heading:"Standard",
        icon:'',
        facility1:"Facility No One",
        facility2:"Facility No Two",
        facility3:"Facility No Three",
        facility4:"Facility No Four",
        facility5:"Facility No Five",
        facility6:"Facility No Six",
        facility7:"Facility No Seven",
        facility8:"Facility No Eight",
        subheading:"$20.99",
        para:"Per Month",
        button:"Buy Now"
       
    } ,   
    {
        heading:"Premium",
        icon:'',
        facility1:"Facility No One",
        facility2:"Facility No Two",
        facility3:"Facility No Three",
        facility4:"Facility No Four",
        facility5:"Facility No Five",
        facility6:"Facility No Six",
        facility7:"Facility No Seven",
        facility8:"Facility No Eight",
        subheading:"$30.99",
        para:"Per Month",
        button:"Buy Now"
       
    } , 
      
]

  
export default subscriptiondata