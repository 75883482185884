import React from "react";

const Card = ({ title, detail,subhead,icon,para }) => {
  return (
    <div className="bg-white h-[270px]  w-[380px] p-4  rounded-lg shadow-sm">
      <h2 className="text-xl font-semibold text-indigo-950 pb-3">{title}</h2>
      <p className=" text-slate-500 pb-4">{detail}</p>
      <span className="flex mt-2">
      <img className="w-15 h-15 rounded-full" src={icon} alt="img"/>
       <div className="ms-2">
        <h3 className="text-indigo-950 text-base font-bold  leading-tight">{subhead}</h3>
        <p className="text-base text-slate-300">{para}</p>
       </div>
      </span>
    </div>
  );
};

export default Card;
