import React, { useState, useEffect } from 'react';
import Navbar from '../component/Navbar';
import image from '../images/usernew.svg';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { updateDoc } from 'firebase/firestore';
import { getAuth, updateProfile } from 'firebase/auth';
import { db } from '../firebase-config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { deleteObject } from 'firebase/storage';



const Setting = () => {

  const [userData, setUserData] = useState('');

  const [selectedFile, setSelectedFile] = useState({
    file: null,
    previewURL: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateName, setUpdateName] = useState(userData ? userData.displayName : '');
  const [updateEmail, setEmail] = useState(userData ? userData.email : '');
  const [updateNumber, setUpdateNumber] = useState(userData ? userData.phoneNumber : '');
  const [showAlert, setShowAlert] = useState(false);
  const [imageUpload, setImageUpload] = useState(null)
  const [editMode, setEditMode] = useState(false);


  const handleNameChange = (e) => {
    setUpdateName(e.target.value);
  };
  const handleNumberChange = (e) => {
    console.log('Phone number changed:', e.target.value);
    setUpdateNumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleButtonClick = () => {
    setEditMode(true);
  };
  const handleCancelButton = () => {
    setUpdateName(userData ? userData.displayName : '');
    setEmail(userData ? userData.email : '');
    setUpdateNumber(userData ? userData.phoneNumber : '');
    setEditMode(false);
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  const currentUser = getAuth().currentUser;
  const handleSaveChanges = async () => {
    console.log('updateNumber:', updateNumber);
    setEditMode(false);


    // Update user profile in Firebase Auth
    const currentUser = getAuth().currentUser;

    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = doc(db, 'users', userId);

      try {
        await updateDoc(userRef, {
          displayName: updateName || userData.displayName,
          email: updateEmail || userData.email,
          phoneNumber: updateNumber || userData.phoneNumber,
          // Add other fields you want to set
        });
        await updateProfile(currentUser, {
          displayName: updateName || currentUser.displayName,
          email: updateEmail || currentUser.email,
          phoneNumber: updateNumber || currentUser.phoneNumber,
          // Add other fields you want to update
        });
        fetchUserData();

      } catch (error) {
        console.error('Error updating user profile in Firestore:', error.message);
      }
    } else {
      console.error('User is not authenticated.');
    }
    // Close the modal
    setShowAlert(true);

    // Set a timeout to hide the alert after 3 seconds (3000 milliseconds)
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    setIsModalOpen(false);
  };
  const fetchUserData = async () => {
    try {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userId = user.uid;
          const userDoc = await getDoc(doc(db, 'users', userId));

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);

            if (data.profileImageURL) {
              setSelectedFile({
                file: null,
                previewURL: data.profileImageURL,
              });
            } else {
              setSelectedFile({
                file: null,
                previewURL: null,
              });
            }
          } else if (user.displayName && user.email) {
            setUserData({
              displayName: user.displayName,
              email: user.email,
              // Add other properties if needed
            });

            setSelectedFile({
              file: null,
              previewURL: null,
            });
          } else {
            console.log('User not found');
          }
        } else {
          console.log('User not authenticated');
        }
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const handleFileUpload = async (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedFile({
          file: file,
          previewURL: reader.result,
        });
      };

      reader.readAsDataURL(file);

      const userId = getAuth().currentUser.uid;

      const storage = getStorage();
      const storageRef = ref(storage, `images/${userId}/${file.name}`);

      try {
        const fileSnapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(fileSnapshot.ref);

        // Now you can use downloadURL as needed, for example, store it in Firestore
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          profileImageURL: downloadURL,
          // Other fields...
        });

        console.log('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error.message);
      }
    } else {
      // If no file is selected, clear the selectedFile state
      setSelectedFile({
        file: null,
        previewURL: null,
      });
    }
  };

  const handleRemoveImage = async () => {
    try {
      const userId = getAuth().currentUser.uid;

      // Get the current user's document reference
      const userRef = doc(db, 'users', userId);

      // Get the user document data
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      // Check if there is a profileImageURL in the user data
      if (userData && userData.profileImageURL) {
        // Delete the image from Storage
        const storage = getStorage();
        const imageRef = ref(storage, userData.profileImageURL);
        await deleteObject(imageRef);
      }

      // Update Firestore to remove the profileImageURL field
      await updateDoc(userRef, {
        profileImageURL: null,
      });

      // Clear the selectedFile state to remove the image from UI
      setSelectedFile({
        file: null,
        previewURL: null,
      });

      console.log('Image removed successfully!');
    } catch (error) {
      console.error('Error removing image:', error.message);
    }
    window.reload()
  };




  return (
    <AuthenticatedLayout>
      <Navbar heading="Settings" showbuton={true} />
      <div className="container mb-5 mt-4 p-6 bg-white md:w-[550px] lg:w-[550px] xl:w-[650px] mx-auto">
        <h1 className="text-2xl font-semibold text-[#290064] mb-4">Profile Photo</h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="flex items-center mb-4 border-r border-gray-200 lg:border-5-r lg:flex-row">
            <div className="border rounded-full bg-slate-100 h-20 w-20 flex justify-center items-center">
              {/* <img
                src={userData.profileImageURL ? userData.profileImageURL : selectedFile ? selectedFile.previewURL : image}
                alt={selectedFile ? selectedFile.previewURL : image}
                className="rounded-full "
              /> */}
              <img
                src={selectedFile && selectedFile.previewURL ? selectedFile.previewURL : userData.profileImageURL || image}
                alt={selectedFile && selectedFile.previewURL ? selectedFile.previewURL : image}
                className="rounded-full"
              />
            </div>
            <div className="flex flex-col lg:flex-col lg:items-center ms-3">
              <label htmlFor="fileInput" className="font-semibold py-1 px-3 whitespace-nowrap rounded-5 mx-2 text-blue-900  lg:mb-0 cursor-pointer" style={{ border: "2px solid midnightblue" }}>
                Upload Photo
                <input
                  type="file"
                  id="fileInput"
                  className="hidden "
                  onChange={(e) => handleFileUpload(e)} // handleFileUpload is a function to handle file selection
                />
              </label>
              <button className="font-semibold py-1 px-3 rounded text-blue-900 lg:ml-2" onClick={handleRemoveImage}>
                Remove
              </button>
            </div>
          </div>
          <div className="flex flex-col ms-4">
            <h2 className="text-xl text-[#290064] mb-2">Image Requirements</h2>
            <ul className="list-decimal list-inside text-zinc-800 text-sm font-normal font-Roboto leading-tigh">
              <li>Min. 400 x 400px</li>
              <li>Max. 2MB</li>
              <li>Your face or company logo</li>
            </ul>
          </div>
        </div>
        <div className={`mt-6 ${showAlert ? '' : 'hidden'}`}>
          <div className="flex items-center p-2 mb-4 text-green-800 rounded-lg bg-green-50" role="alert">
            <div className="ml-3 text-sm font-medium">
              <strong>Successfully Saved</strong> Your profile settings have been saved.
            </div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg  p-1.5  inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
              data-dismiss-target="#alert-3"
              onClick={handleClose}
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
        </div>
        <h2 className="text-xl font-semibold text-[#290064] mb-2">User Details</h2>
        {userData ? (
          <div>
            {editMode ? (
              <>
                <div>
                  <label>User Name: </label>
                  <input
                    className="bg-[#F2F4F8] my-2 p-2 text-sm w-full focus:outline-none"
                    placeholder='Enter Your Name'
                    type="text"
                    value={updateName}
                    onChange={handleNameChange}
                  />
                </div>
              </>
            ) : (
              <>
                <label>User Name: </label>

                <p className="bg-[#F2F4F8] my-2 p-2 text-sm">{userData.displayName}</p>
              </>
            )}

            {editMode ? (
              <div>
                <label>Email: </label>
                <input
                  className="bg-[#F2F4F8] my-2 p-2 text-sm w-full focus:outline-none"
                  type="email"
                  placeholder='Enter Your Email'
                  value={updateEmail}
                  onChange={handleEmailChange}
                />
              </div>
            ) : (
              <>
                <label>Email: </label>
                <p className="bg-[#F2F4F8] my-2 p-2 text-sm">{userData.email}</p>

              </>
            )}

            {userData.phoneNumber && (
              <>
                <label>Number: </label>
                {editMode ? (
                  <input
                    className="bg-[#F2F4F8] my-2 p-2 text-sm w-full focus:outline-none"
                    type="text"
                    placeholder='Enter Your Number'
                    value={updateNumber}
                    onChange={handleNumberChange}
                  />
                ) : (
                  <p className="bg-[#F2F4F8] my-2 p-2 text-sm">{userData.phoneNumber}</p>
                )}
              </>
            )}

            <div className="flex justify-end">
              {editMode ? (
                <>
                  <button
                    onClick={handleSaveChanges}
                    type="submit"
                    className="whitespace-nowrap bg-violet-600 hover:bg-violet-800 hover:text-white text-white font-semibold py-2 px-4 rounded mt-4 lg:mt-0"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancelButton}
                    type="button"
                    className="whitespace-nowrap bg-red-600 hover:bg-red-800 hover:text-white text-white font-semibold py-2 px-4 rounded ml-2 mt-4 lg:mt-0"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  onClick={handleButtonClick}
                  type="submit"
                  className="whitespace-nowrap bg-violet-600 hover:bg-violet-800 hover:text-white  text-white font-semibold py-2 px-4 rounded mt-4 lg:mt-0"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}

      </div>

    </AuthenticatedLayout>
  );
};

export default Setting;
