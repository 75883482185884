import React from 'react'

const PostallPlatform = ({ icon, heading, paragraph }) => {
  return (
    <>
      <div className=' mt-3 me-4  p-4 mb-3 cardbghover  rounded'>
        {/* <div className='flex justify-center items-center md:justify-start'> */}
          <img src={icon} alt="Navigate to Home" />
        {/* </div> */}
          <h1 className="text-neutral-600 lg:text-base text-sm font-normal ">{heading}</h1>
          <p className="lg:w-[115px] text-neutral-600 text-[10px] font-normal  leading-[14px]">{paragraph}</p>
      </div>
      
      
    </>
  )
}

export default PostallPlatform