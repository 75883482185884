import React from 'react'


const LandingPageCards = ({image,para1,para2}) => {
    return (
        <>
            <div >
               <img className='lg:w-[97px] w-[50px] h-full lg:h-[90px] mb-4' src={image} alt="img" />
               <h1 className='lg:text-lg mb-4 text-slate-700 font-bold'>{para1}</h1>
               <p className='text-zinc-700 text-sm'>{para2}</p>
            </div>
        </>
    )
}

export default LandingPageCards