import React from 'react'
import GuestLayout from '../Layouts/GuestLayout'
import { useNavigate } from 'react-router-dom'

const TermsAndCondition = () => {
    const navigate = useNavigate()
    const handleBack = () => {
        navigate('/')
    }

    return (
        <GuestLayout>
            <>
                <div className='bg-[#F5F6FA] '>
                    <center >
                        <h1 className='text-[34px] font-semibold p-3 text-gray-500'>Terms & Conditions</h1>
                        <p className='text-[20px] text-gray-500 p-3 text-bold w-full md:w-[810px]'>We are on a mission to make the web a better place. The following terms, as well as our Policy and Terms of Service, apply to all users.
                        </p>
                        <p className='mb-3 text-[16px] text-gray-400 italic'>
                            Last Update: Jan 01, 2024
                        </p>
                    </center>
                    <center className='border mx-5 bg-white rounded-1  p-4 text-start text-gray-500'>
                        <h1 className='flex justify-start text-[28px] font-bold text-gray-600'>Terms And Conditions</h1><br />
                        <p className='flex justify-start'>We’ve decided to make these Terms available under a Creative Commons license. You can grab a copy of these Terms and other legal documents on Github. You’re more than welcome to copy them, adapt them, and repurpose them for your own use.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Introduction</h2>
                        <br />
                        <p>
                            Welcome to Socialify.ai! These Terms and Conditions ("Terms") govern your use of the Socialify.ai software and services (collectively, the "Service"), which is owned and operated by Socialify.ai. By accessing or using our Service, you agree to be bound by these Terms and all applicable laws and regulations.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Service Description</h2><br />
                        <p>
                            Socialify.ai is an AI-powered software designed to assist users in generating pictures, writing captions, and managing multiple social media accounts from a single platform. Our Service uses advanced algorithms to facilitate content creation and social media management.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>User Agreement</h2><br />
                        <p>
                            By using Socialify.ai, you warrant that you are at least 18 years of age and possess the legal authority to enter into this Agreement. You agree to use the Service in compliance with these Terms and all applicable local, state, national, and international laws, rules, and regulations.
                        </p><br />


                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Account Registration and Security</h2>
                        <br />
                        <p>

                            Account Creation: Users must register for an account by providing accurate, current, and complete information as prompted by the registration process.
                            Account Security: You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>User-Generated Content:</h2>
                        <br />
                        <p>
                            Users may upload content to the Service. By doing so, you grant Socialify.ai a non-exclusive, worldwide, royalty-free license to use, reproduce, display, and distribute this content in connection with the Service.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Users agree not to use the Service to:</h2>
                        <br />
                        <p>
                            Create, upload, or distribute content that is unlawful, defamatory, obscene, invasive of privacy, or otherwise objectionable.
                            Violate or infringe upon the rights of others, including intellectual property rights.
                            Transmit viruses, malware, or any other malicious code.
                            Engage in any activity that interferes with or disrupts the Service.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Limitation of Liability</h2>
                        <br />
                        <p>
                            Socialify.ai shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangibles, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Payment Terms</h2>
                        <br />
                        <p>
                            If applicable, Socialify.ai's payment terms will be specified, including billing cycles, subscription fees, and any applicable taxes.</p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Amendments and Changes to Terms</h2>
                        <br />
                        <p>
                            Socialify.ai reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'> Governing Law</h2>
                        <br />
                        <p>
                            These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Contact Information</h2>
                        <br />
                        <p>
                            For any questions about these Terms, please contact us at [team@novabuzzmarketing.com].
                        </p>
                        <br />
                        <h2 className='text-[28px] font-bold text-gray-600'>Entire Agreement</h2>
                        <br />
                        <p>
                            These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.
                        </p>
                        <br />
                        <button onClick={handleBack} className='border rounded-1 px-3 hover:bg-violet-900  hover:text-white'>Back
                        </button>
                    </center>
                </div>
            </>
        </GuestLayout>
    )
}

export default TermsAndCondition