import React, { useEffect, useState } from 'react'
import { data3 } from '../component/insta'
import CreateSocialCom from '../component/CreateSocialCom'
import Navbar from '../component/Navbar'
import { table1 } from '../component/SidebarData';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
import DisconnectModal from '../component/DisconnectModal';
import image from '../images/apilinkbutton.svg'
import image1 from '../images/btnnotactive.svg'
import axios from 'axios'; // Import axios

const ApiChannels = () => {
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getUserFollowers = async (accessToken) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/me?manage_pages=1&access_token=${accessToken}`
      );
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching user followers:', error.message);
      console.error('Full error object:', error);
    }
  };
   useEffect(()=>{
  getUserFollowers('EAAMmr9LndBEBOZBnm98iseGhdsO2kD7RuSF3A2YAq0p2GjQGua4g0H1YcrAjgpmotGBMDjOV305xtlM49zH9ZBuHV761LeOlIZBNtEa5OwZBLwQbArKDR8tfIZCuSKAvETk22M3dahZBcxj6S7ZCrtfn4e8vPduQfW56xpFBXrkdPAZBET8st8D5PrxPJfWF0OoASlRIgZCZBzAE442ZAKQb2z26cAxvo0qpf4ZBHhF6MRMbGtwGBFVQhw7dq6CxftCeZBgZDZD');

   },[])
  // Call the function with the user's access token
  const handleButtonClick = () => {
    setIsActive(!isActive);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsActive(!isActive);
    console.log('close')
  };


  return (
    <AuthenticatedLayout>
      <Navbar heading="API Channels" showbuton={true} />
      <div className='w-full '>
        <div className='ms-3 mt-4'>
          <h1 className="text-violet-950 lg:text-2xl ms-2 font-bold  leading-relaxed">Social media</h1>
          <p className="text-gray-700 text-sm ms-2 lg:text-base font-normal  leading-snug ">Integrate different social media accounts to use bot, auto reply, social posting etc features.</p>
          <div className='row p-0 m-0 mt-3'>

            {data3.map((social, index) => (
              <div key={index} className='w-[140px] lg:w-1/6 ms-2 h-36 lg:h-40 bg-white rounded-[10px]  mx-auto shadow border border-zinc-200 mb-3 lg:me-4 '>
                <CreateSocialCom key={index} showmodal={true} img={social.image} text={social.para} para={social.para} head={social.head} showCheckbox={false} className='text-blue-950 text-sm lg:text-base text-opacity-80 font-normal' />
              </div>

            ))}
          </div>
        </div>
        <h1 className="text-violet-950 lg:text-2xl font-bold  leading-relaxed mx-4">Already Linked</h1>
        <p className="text-gray-700 text-sm lg:text-base font-normal  leading-snug ms-4">Integrate social media accounts .</p>
        <div className='row p-0 m-0 mx-3'>
          <div className=' my-3 col-12 border rounded-3 overflow-x-auto bg-white '>
            <h1 className='lg:text-2xl mt-3 text-violet-950 '>Already Linked</h1>
            <table className='table table-responsive'>
              <thead>
                <tr className='tablehead'>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>Disconnect</td>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>Social</td>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>Followers</td>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>Engagement</td>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>View</td>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>Likes</td>
                  <td className='text-sm lg:text-base' style={{ color: "#290064" }}>Shares</td>
                </tr>
              </thead>
              <tbody>
                {table1.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <button onClick={handleButtonClick} className={`  rounded-full   ${isActive ? 'bg-violet-600' : ''}`}>
                        {isActive ? (
                          <img src={image} alt='Active Image' />
                        ) : (
                          <img src={image1} alt='Inactive Image' />
                        )}

                      </button>
                    </td>
                    <td className='py-3'>
                      <img className='w-5 h-5' src={data.image} alt={data.post} />
                    </td>
                    <td className='py-3' style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.post}</td>
                    <td className='py-3' style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.percent}</td>
                    <td className='py-3' style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.views}</td>
                    <td className='py-3' style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.likes}</td>
                    <td className='py-3' style={{ color: 'rgba(7, 35, 112, 0.80)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>{data.share}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isModalOpen && <DisconnectModal closeModal={handleCloseModal} />}
    </AuthenticatedLayout>
  )
}

export default ApiChannels 