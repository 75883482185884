import instaLogo from '../images/Social insta.png';
import facebookLogo from '../images/Social face.png';
import twitterLogo from '../images/Social twit.png';
import tiktokLogo from '../images/Social tik.png';
import linkdeinLogo from '../images/Social link.png';
import pinterstLogo from '../images/Social pint.png';
//For Api Channels page 
import instaLogo1 from '../images/insta.png';
import facebookLogo1 from '../images/facebook.png';
import twitterLogo1 from '../images/TwitterNew.svg';
import tiktokLogo1 from '../images/tiktok.png';
import linkdeinLogo1 from '../images/linkedin.png';
import pinterstLogo1 from '../images/pinterest.png';
import girlpic from '../images/girlpic.png'

export const data2 = [
    {
        image: instaLogo,
        para: "Instagram"
    },
    {
        image: facebookLogo,
        para: "Facebook"
    },
    {
        image: linkdeinLogo,
        para: "Linkedin"
    },
    {
        image: twitterLogo,
        para: "Twitter"
    },
    {
        image: pinterstLogo,
        para: "Pinterest"
    },
    {
        image: tiktokLogo,
        para: "TikTok"
    },
]

export const data3 = [
    {
        image: instaLogo1,
        para: "Instagram",
        platformpath:"/instagram-login"
    },
    {
        image: facebookLogo1,
        para: "Facebook",
        platformpath:"/facebook-login"

    },
    {
        image: linkdeinLogo1,
        para: "Linkedin",
        platformpath:"/linkedin-login"

    },
    {
        image: twitterLogo1,
        para: "Twitter",
        platformpath:"/twitter-login"

    },
    {
        image: pinterstLogo1,
        para: "Pinterest",
        platformpath:"/pinterest-login"

    },
    {
        image: tiktokLogo1,
        para: "TikTok",
        platformpath:"/tiktok-login"

    },
]

export const data4 = [
    { 
        para:"By Clicking this Button You will be redirect to Instagram account",
        image: instaLogo1,
        head:"Login With Instagram",
        platformpath:"/instagram-login"
        
    },
    { 
        para:"By Clicking this Button You will be redirect to Facebook account",
        image: facebookLogo1,
        head:"Login With Facebook",
        platformpath:"/facebook-login"
        
    },
    { 
        para:"By Clicking this Button You will be redirect to Linkedin account",
        image: linkdeinLogo1,
        head:"Login With Linkedin",
        platformpath:"/linkedin-login"
        
    },
    { 
        para:"By Clicking this Button You will be redirect to Twitter account",
        image: twitterLogo1,
        head:"Login With Twitter",
        platformpath:"/twitter-login"
        
    },
     { 
        para:"By Clicking this Button You will be redirect to Pinterest account",
        image: pinterstLogo1,
        head:"Login With Pinterest",
        platformpath:"/pinterest-login"
        
    },
    { 
        para:"By Clicking this Button You will be redirect to Tiktok account",
        image: tiktokLogo1,
        head:"Login With Tiktok",
        platformpath:"/tiktok-login"
        
    },
]

export const landingpagetestimonial = [
    {
        heading:"The best management tools",
        paragraph:"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident",
        img:girlpic,
        subhead:"Sophier Turner",
        subpara:"CEO at Webflow agency"

    },
    {
        heading:"The best management tools",
        paragraph:"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident",
        img:girlpic,
        subhead:"Sophier Turner",
        subpara:"CEO at Webflow agency"

    },
    {
        heading:"The best management tools",
        paragraph:"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident",
        img:girlpic,
        subhead:"Sophier Turner",
        subpara:"CEO at Webflow agency"

    },
    {
        heading:"The best management tools",
        paragraph:"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure sint amet occaecat cupidatat non proident",
        img:girlpic,
        subhead:"Sophier Turner",
        subpara:"CEO at Webflow agency"

    },
   
]