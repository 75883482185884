import React, { useState } from 'react';
import AddressDropdown from '../component/AddressDropdown';

const PurchaseModal = ({ isOpenmodal, isClosemodal }) => {
    const [selectedAddress, setSelectedAddress] = useState(null);

    const handleSelectAddress = (address) => {
        // Do something with the selected address, e.g., update state
           setSelectedAddress(address)
    };
    return (
        <div>
            <div className={`fixed mb-4 top-0 left-10 right-0 z-50 ${isOpenmodal ? 'block' : 'hidden'}  p-4 overflow-x-hidden overflow-y-auto md:inset-0 `}
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="relative w-full max-w-xl h-full">
                    <div className="lg:relative lg:top-[0px] lg:left-[400px] px-[50px] bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className=" p-4 relative right-9 rounded-t dark:border-gray-600">
                            <svg   className="text-purple-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-5 h-5 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={isClosemodal} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M15 20L6.7323 12.5909C5.7559 11.7159 5.7559 10.2841 6.7323 9.40909L15 2" stroke="#615E83" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            
                        </div>
                        <form className=''>
                            <div className="text-violet-950 text-xs font-normal mb-3  leading-[13.20px]">Card Information</div>
                            <div className="relative ">
                                <input
                                    type="text"
                                    className="border-[#D9D9D9] px-2  border-1 rounded-sm lg:w-[470px] w-full h-11 pr-10"
                                    placeholder="1234 1234 1234 1234"
                                />
                                <img
                                    src={require("../images/image 40.png")}
                                    className="absolute  right-[15px] top-1 w-[142px] h-[39px]"
                                    alt=""
                                />
                            </div>
                            <div className="flex lg:w-96 w-90 mt-2">
                                <input
                                    type="text"
                                    className="border-[#D9D9D9] px-2 border-1 rounded-sm w-full lg:w-[440] h-11"
                                    placeholder="MM/YY"
                                />
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="border-[#D9D9D9] px-2 border-1 rounded-sm w-[100%] lg:w-[166%] h-11"
                                        placeholder="CVC"
                                    />
                                    <img
                                        src={require("../images/image 41.png")}
                                        className="absolute -right-[1px] lg:-right-[80px] top-1 w-[39px] h-[29px]"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="text-violet-950 text-xs font-normal  leading-[13.20px] mt-3">Name On Card</div>
                            <input className="w-full lg:w-[440] h-[43px] border border-zinc-300 mt-2" />
                            <div className="text-violet-950 text-xs font-normal  leading-[13.20px] mt-3">
                                Billing Address
                                <AddressDropdown onSelectAddress={handleSelectAddress} selectedAddress={selectedAddress}/></div>
                            <div className="text-blue-950 text-[10px] font-normal underline leading-[11px] mt-3 mb-2">Enter Address manually</div>
                            <div className='flex flex-row border border-zinc-300 w-full lg:w-[440] h-fit p-2'>
                            <input type='checkbox' />
                            <div className="flex flex-col mt-1 px-3  bg-white rounded-[5px] ">
                                <div className="text-sm font-bold text-violet-950   leading-[13.20px] mt-3 py-1">Securely Save my information for 1 click checkout</div>
                                <div className=" flex flex-nowrap  text-violet-950 text-xs lg:text-sm lg:mt-1">Pay Faster on Folk and everywhere link is accepted.</div>
                            </div>
                            </div>
                            <input type='submit' value='Subscribe' className="w-full lg:w-[440] text-white h-11 bg-gradient-to-r bg-violet-600 hover:bg-violet-900 rounded-[5px] mt-3" />
                            <div className="lg:w-[440px]  text-sm  font-light  leading-[14px] mt-3 pb-5">By the Confirming Your Subscription, you allow folk to change your card<br />for this payment and future payments in accordance with their terms. you can always cancel your subscription.</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseModal;
