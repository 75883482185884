import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const Chart = () => {
  const [series] = useState([{
    data: [21, 22, 10, 28, 16, 21, 13]
  }]);

  const [options] = useState({
    chart: {
      height: 100,
      type: 'bar',
     
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        fill: {
          type: 'gradient',
          gradient: {
            colorStops: ['#812FFC', '#F0E5FC'],
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    colors: ['#8739FB', '#F0E5FC'] // Define the colors you want to use
  });
  

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default Chart;
