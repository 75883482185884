import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SideBar from '../component/SideBar'

export default function AuthenticatedLayout({ children }) {
    const navigate = useNavigate()
    const [user, setUser] = useState(false)
    useEffect(() => {

        const token = localStorage.getItem('userToken')
        if (
            token
        ) {
            setUser(true)
        }
        else {
            navigate('/login')
        }


    }, [])

    return (
        <>
            {user &&

                (<div className=' bg-gray-200 flex'>
                    <div className=' col-lg-2 d-none d-lg-block'>
                        <SideBar />
                    </div>
                    <div className='col-12 col-lg-10  p-0 ' style={{ height: "100vh", overflow: "auto" }}>
                        {children}
                    </div>
                </div>)
            }
        </>
    )
}
