import React, { useEffect } from 'react';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { db } from '../firebase-config/firebase';
import { setDoc, doc } from 'firebase/firestore';

const FacebLogin = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      console.log('FB SDK initialized');
      window.FB.init({
        appId: '886961529648145',
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      });
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);
 
   
  const responseFacebook = async (response) => {
    let userData = {
      id: response.id,
      name: response.name,
      email: response.email,
      // Add other fields as needed
     };
    console.log('Facebook Response:', userData);

    try {
      console.log('User ID:', response.id);
      const userDocRef = doc(db, 'facebookdata', response.id);
      console.log('djnddjndjndjnd')
      await setDoc(userDocRef, userData);

      console.log('Data successfully written to Firestore');
    } catch (error) {
      console.error('Error writing to Firestore:', error);
    }
  };
  
  useEffect(()=>{
  },[])
  return (
    <div>
      <LoginSocialFacebook
        // appId='886961529648145'
        appId='400725782396495'
        onResolve={(response) => {
          console.log(response);
        }}
        onReject={(error) => {
          console.log(error);
        }}
        scope='email'
      >
        <FacebookLoginButton callback={(response) => responseFacebook(response)} />
      </LoginSocialFacebook>
    </div>
  );
};

export default FacebLogin;
