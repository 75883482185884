import React from 'react';

const TableRow = ({ name, imageSrc, date, time, signalCount, status,onclick }) => {
    const isPaid = status.toLowerCase() === 'paid';
    const bgColor = isPaid ? 'bg-[#03BA20]' : 'bg-[#E6000D]';
  return (
    <tr className='ps-4'>
      <td className='py-4 '>
        <div className='flex'>
          <span><img className='w-[52px] h-[53px] rounded-full' src={imageSrc} alt={name} /></span>
          <span className='ms-2 pt-3 font-semibold whitespace-nowrap'>{name}</span>
        </div>
      </td>
      <td className='py-4 '>
        <div className='flex flex-col pt-3'>
          <span className='text-black text-sm font-medium whitespace-nowrap'>{date}</span>
          <span className='text-slate-200 text-sm whitespace-nowrap'>{time}</span>
        </div>
      </td>
      <td className='py-4'>
        <div className='pt-3'>
          <span className='text-black text-sm font-medium whitespace-nowrap'>{signalCount} Signal</span>
        </div>
      </td>
      <td className='py-4'>
        <div className='pt-3'>
        <span className={`px-3 py-0.5 text-white rounded-full ${bgColor}`}>{status}</span>
        </div>
      </td>
      <td className='py-4'>
        <div className='pt-3 ps-4'>
          <svg className='cursor-pointer' onClick={onclick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 7H16M9 11H15M6.73 19.7C7.55 18.82 8.8 18.89 9.52 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z" stroke="black" stroke-width="1.5" stroke-linecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
