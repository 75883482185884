import React from 'react'
import icon from '../images/cog.png';
import iconbell from '../images/bell.png';
import { useState } from 'react';
import SideBar from './SideBar';
import { NavLink, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'
import { auth } from "../firebase-config/firebase"
import { notification } from '../component/Inputfielddata';


const Navbar = ({ heading, svg, showbuton }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);

  };
  const navigate = useNavigate()

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("userToken");
      navigate('/login')
      console.log('User logged out successfully');
    } catch (error) {
      // Handle the specific error
      console.error('Error logging out:', error);
    }
  }
  const handleBackButtonClick = () => {
    window.history.back();
  };
  return (
    <>
      <div className="w-100 container-fluid   bg-white p-0 m-0 h-[81px] pt-3 ps-1 " >
        <div className="container-fluid d-flex  justify-between">
          <div className='flex'>
            <span>
              <svg onClick={toggleSidebar} className='mt-1  d-lg-none ' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M3 7H21M3 12H21M3 17H21" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
              {isSidebarOpen && <div className='d-lg-none -mt-11 -ms-5'>
                <SideBar toggle={toggleSidebar} />
              </div>}
            </span>

            {showbuton &&
              <button className='px-2 mt-[-1px] sm:mt-[-5px] lg:mt-1 sm:px-3' onClick={handleBackButtonClick}>{svg}
                <svg className='w-3 h-3' xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                  <path d="M0.499999 9.36602C-0.166667 8.98112 -0.166667 8.01888 0.5 7.63397L13.25 0.27276C13.9167 -0.112141 14.75 0.368984 14.75 1.13878L14.75 15.8612C14.75 16.631 13.9167 17.1121 13.25 16.7272L0.499999 9.36602Z" fill="#290064" />
                </svg>
              </button>}
            <h1 className='text-xl mt-1 lg:text-3xl ms-2 font-bold text-purple-900'>{heading}</h1>
          </div>
          <div className="flex space-x-3 mt-2 me-2">
            <button
              type="button"
              className="  rounded-2 -mt-[2px] "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img className=' lg:h-[24px] lg:w-[24px]   ' src={icon} alt='' />
            </button>
            <ul className="dropdown-menu border-1  w-[221px]  p-3">
              <li className="px-2 py-2 ">
                <NavLink className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-2 active:text-white    flex items-center   text-blue-950 text-sm font-medium bg-gradient-to-r from-violet-400 to-violet-600 rounded-full"
                    : "relative px-4 py-2 hover:text-white flex items-center  text-blue-950 text-sm font-medium rounded-full  hover:bg-gradient-to-r from-violet-400 to-violet-600"
                } to="/sett">Edit Profile</NavLink>
              </li>
              <li className="px-2 py-2 ">

                <NavLink className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-2 active:text-white mb-2 mt-2  flex items-center   text-blue-950 text-sm font-medium bg-gradient-to-r from-violet-400 to-violet-600 rounded-full"
                    : "relative px-4 py-2 hover:text-white flex items-center  text-blue-950 text-sm font-medium rounded-full  hover:bg-gradient-to-r from-violet-400 to-violet-600 "
                } to="/subs">Subscription</NavLink>
              </li>
              <li className="px-2 py-2 ">

                <NavLink className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-2 text-white mt-2 mb-2  flex items-center    text-sm font-medium bg-gradient-to-r from-violet-400 to-violet-600 rounded-full"
                    : "relative px-4 py-2 hover:text-white flex items-center  text-blue-950 text-sm font-medium rounded-full  hover:bg-gradient-to-r from-violet-400 to-violet-600 "
                } to="/billi">Billing</NavLink>
              </li>
              <li className="px-2 py-2 ">
                <NavLink className={({ isActive }) =>
                  isActive
                    ? "relative px-4 py-2 mt-2 active:text-white   flex items-center   text-blue-950 text-sm font-medium bg-gradient-to-r from-violet-400 to-violet-600 rounded-full"
                    : "relative px-4 py-2 hover:text-white flex items-center  text-blue-950 text-sm font-medium rounded-full  hover:bg-gradient-to-r from-violet-400 to-violet-600 "
                } to="/notifi">Notification
                </NavLink>
              </li>
              <li className='px-2 py-2 '>
                <button className='w-full px-4 py-2 hover:text-white flex items-center  text-blue-950 text-sm font-medium rounded-full  hover:bg-gradient-to-r from-violet-400 to-violet-600' onClick={logout}>
                  Logout
                </button>
              </li>
            </ul>
            {/* <button
              type="button"
              className="dropdown-trigger  rounded-2 -mt-[7px] "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img className=' lg:h-[24px] lg:w-[24px]  ' src={iconbell} alt='' />
            </button> */}
            <button data-bs-toggle="dropdown"
              aria-expanded="false" type="button" className="relative inline-flex items-center text-sm font-medium text-center text-white ">
              <img className=' lg:h-[24px] lg:w-[24px]  ' src={iconbell} alt='' />
              <div className="dropdown-trigger  absolute inline-flex items-center justify-center w-5 h-5 text-[10px] font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1 dark:border-gray-900">20</div>
            </button>
            <ul className="dropdown-menu border-1">
              <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownRadioHelperButton">
                <li>
                  <div className='h-[300px] overflow1 my-2 w-[250px] md:w-[450px]  items-center'>
                    <div className='row '>
                      {notification.map((item, index) => (
                        <div className=' ' key={index}>
                          <Notifications
                            imag={item.image}
                            head={item.heading}
                            parag={item.paragraph}
                            minu={item.time}
                            timel={item.timelimit}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
              </ul>
            </ul>
          </div>

        </div>
      </div>

    </>
  )

}
const Notifications = ({ imag, head, parag, minu, timel }) => {
  return (
    <>
      <div className="lg:w-[687px] h-fit lg:h-[79px]  bg-white rounded p-3 mt-2 mx-auto my-3  flex items-center pb-2 py-3">
        <div className="mr-6 lg:mr-0">
          <img className="lg:w-[50px] lg:h-[50px] me-3" src={imag} alt="img" />
        </div>
        <div className="col-span-2 ">
          <div className='justify-between'>
            <h1 className='text-violet-950 text-xs lg:text-base font-medium '>{head}</h1>
            <p className='text-neutral-600  text-[9px] lg:text-[10px] '>{parag}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-violet-600 text-[10px] font-normal'>{minu}</p>
            <p className='text-violet-600 text-[10px] font-normal'>{timel}</p>
          </div>

        </div>
      </div>


    </>
  );
};

export default Navbar
