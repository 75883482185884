// import './App.css';
import Dashboard from './pages/Dashboard';

// import SideBar from './component/SideBar';
import {Routes ,Route } from 'react-router-dom';
import Subscription from './pages/Subscription';
import ApiChannels from './pages/ApiChannels';
import SocialPosting from './pages/SocialPosting';
import AIContent from './pages/AIContent';
import AIPicture from './pages/AIPicture';
import AIWebsite from './pages/AIWebsite';
import Referals from './pages/Referals';
import Post from './pages/Post';
import Instagram from './pages/Instagram';
import Facebook from './pages/Facebook';
import Linkedin from './pages/Linkedin';
import Twitter from './pages/Twitter';
import Tiktok from './pages/Tiktok'
import Testing from './pages/Testing';
import Subscriptionmap from './component/Subscriptionmap';
import Setting from './pages/Setting';
import Notifications from './pages/Notifications';
import Billing from './pages/Billing';
import Pinterest from './pages/Pinterest';
import CreatePost from './pages/CreatePost';
import TextPost from './pages/TextPost';
import Navbar from './component/Navbar';
import ModalInstaCom from './pages/ModalInstaCom';
import VideoPost from './pages/VideoPost'
import ImagePost from './pages/ImagePost'
import ReelsPost from './pages/ReelsPost'
import StoriesPost from './pages/StoriesPost'
import CreateImagePost from './pages/CreateImagePost'
import CreateVideoPost from './pages/CreateVideoPost'
import CreateReelsPost from './pages/CreateReelsPost'
import CreateStoriesPost from './pages/CreateStoriesPost'

//////
import Landingpage from './pages/Landingpage';
import Login from './pages/Login'
import Signup from './pages/Signup'
import Featurelanding from './pages/Featurelanding';
import FAQs from './pages/FAQ';
import Pricinglanding from './pages/Pricinglanding';
import Forgotten from './pages/Forgotten';
import ResetPasswLink from './pages/ResetPasswLink';
import TermsAndCondition from './pages/TermsAndCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';



function App() {
  return (
       <>
         <Routes>
          <Route path='/' element={<Landingpage/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/forgetpassworn' element={<Forgotten/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
           
            <Route path='/subscription' element={<Subscriptionmap/>}/> 
           <Route path='/subscription' element={<Setting/>}/>
           <Route path='/apichannels' element={<ApiChannels/>}/>
           <Route path='/socialposting' element={<SocialPosting/>}/> 
           <Route path='/postallplatform' element={<Post/>}/> 
           <Route path='/instagram' element={<Instagram/>}/> 
           <Route path='/facebook' element={<Facebook/>}/> 
           <Route path='/linkedin' element={<Linkedin/>}/> 
           <Route path='/twitter' element={<Twitter/>}/> 
           <Route path='/pinterest' element={<Pinterest/>}/> 
           <Route path='/tiktok' element={<Tiktok/>}/> 
           <Route path='/aicontentgenrator' element={<AIContent/>}/>
           <Route path='/aipicturegenerator' element={<AIPicture/>}/>
           <Route path='/aiwebsitebuilder' element={<AIWebsite/>}/>
           <Route path='/referal' element={<Referals/>}/>
                <Route path='/sett' element={<Setting/>}/>
                <Route path='/subs' element={<Subscriptionmap/>}/>
                <Route path='/billi' element={<Billing/>}/>
                <Route path='/notifi' element={<Notifications/>}/>

                
                <Route path='/imagepost' element={<ImagePost/>}/>
                <Route path='/createimagepost' element={<CreateImagePost/>}/>

                <Route path="/createtextpost" element={<CreatePost/>}/>
                <Route path="/textpost" element={<TextPost/>}/>

                <Route path="/videopost" element={<VideoPost/>}/>
                <Route path='/createvideopost' element={<CreateVideoPost/>}/>
                
                <Route path="/reelspost" element={<ReelsPost/>}/>
                <Route path='/createreelspost' element={<CreateReelsPost/>}/>

                 <Route path='/storiespost' element={<StoriesPost/>}/>
                 <Route path='/createstoriespost' element={<CreateStoriesPost/>}/>



                 <Route path='/ResetPass' element={<ResetPasswLink/>}/>
                 <Route path='/terms_condition' element={<TermsAndCondition/>}/>
                 <Route path='/privacy_policy' element={<PrivacyPolicy/>}/>

        
       </Routes> 
      
       </>
  );
}

export default App;
