import React, { useState } from 'react';
import Navbar from '../component/Navbar';
import Search from '../component/Search';
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
import { Link } from 'react-router-dom';
import { TbEyePlus } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import tableimage from '../images/tableimage.png'

// Define a functional component named 'TextPost'.
const StoriesPost = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7; // Set the number of items to display per page

  const handleEdit = (itemId) => {
    // Implement your logic for editing an item
    console.log(`Editing item with ID ${itemId}`);
  };

  const handleDelete = (itemId) => {
    // Implement your logic for deleting an item
    console.log(`Deleting item with ID ${itemId}`);
  };

  // Dummy data, replace with your actual data
  const data = Array.from({ length: 20 }, (_, index) => ({
    id: index + 1,
    postName: `Post two name could be any thing ${index + 1}`,
    socialMedia: 'Social Media',
    actions: 'Edit/Delete',
    status: 'Completed',
    scheduledAt: 'June 30 2023 07:13',
  }));

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Dummy total items count, replace with actual count from your data
  const totalItems = data.length;

  // Calculate the total number of pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <AuthenticatedLayout>
      {/* Render the 'Navbar' component and pass a 'heading' prop with the value "Text Post". */}
      <Navbar heading='Stories Post' showbuton={true} />

      {/* Create a container div with margin-bottom of 80 units. */}
      <div className=' '>

        {/* Create a div with top and bottom margins and a 'flex' layout. */}
        <div className="mb-4 mt-3 flex px-4">
          {/* Render the 'Search' component. */}
          <Search />
          <Link to='/createstoriespost'>
            <button className="whitespace-nowrap w-[108px] lg:w-[220px]  lg:h-[58px] h-[40px]  mt-4  ps-1 py-0 bg-white rounded-[10px] ms-3 border-1 border-violet-600 lg:p-2 lg:pe-4 lg:ps-4 text-violet-600 lg:text-lg text-xs font-normal" type="submit">Create New Post</button>
          </Link>
        </div>
        <div className="w-full px-4 table-responsive-md">

          <table className="table  rounded-md w-full " style={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <thead className=' h-[47px] rounded-5 overflow-auto'>
              <tr className='p-3 rounded-md '>
                <th style={{ backgroundColor: "#EBE5FF" }} className="  colorh1 lg:text-base font-normal text-xs">S:No</th>
                <th style={{ backgroundColor: "#EBE5FF" }} className="col colorh1 lg:text-base font-normal text-xs">Post Name</th>
                <th style={{ backgroundColor: "#EBE5FF" }} className="col colorh1 lg:text-base font-normal text-xs">Social Media</th>
                <th style={{ backgroundColor: "#EBE5FF" }} className="col colorh1 lg:text-base font-normal text-xs">Image</th>
                <th style={{ backgroundColor: "#EBE5FF" }} className="col colorh1 lg:text-base font-normal text-xs">Actions</th>
                <th style={{ backgroundColor: "#EBE5FF" }} className="col colorh1 lg:text-base font-normal text-xs">Status</th>
                <th style={{ backgroundColor: "#EBE5FF" }} className="col colorh1 lg:text-base font-normal text-xs">Scheduled At</th>
              </tr>
            </thead>

            <tbody>

              {currentItems.map((item) => (
                <tr key={item.id} className='p-5 space-y-5 rounded-lg w-full' >
                  <td className='py-4 px-3 text-neutral-600 text-base font-normal my-3'>{item.id}</td>
                  <td className='py-4 text-neutral-600 whitespace-nowrap text-base font-normal'>{item.postName}</td>
                  <td className='py-4 text-neutral-600 whitespace-nowrap text-base font-normal '>{item.socialMedia}</td>
                  <td className='py-4 text-neutral-600 whitespace-nowrap text-base font-normal '>
                    <img src={tableimage} alt='nothsoine' />
                  </td>

                  <td className='py-4 text-neutral-600 whitespace-nowrap text-base font-normal '>
                    <span className='flex gap-3 pt-2'>
                      <TbEyePlus onClick={() => handleEdit(item.id)}
                        className="cursor-pointer text-[#001D6C] hover:text-blue-200 h-3 w-3" />
                      <RiDeleteBin6Line onClick={() => handleDelete(item.id)}
                        className="cursor-pointer text-[#001D6C] hover:text-blue-200 h-3 w-3" />
                    </span>
                  </td>
                  <td className='py-4 text-neutral-600 text-base font-normal'>{item.status}</td>
                  <td className='py-4 text-neutral-600 text-base whitespace-nowrap font-normal'>{item.scheduledAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-end mb-4">
            <button
              onClick={handlePreviousPage}
              className={` mx-1 w-[72.19px] h-[29.85px] bg-white rounded-[10px] shadow border-1 hover:text-black text-violet-600 border-violet-600 ${currentPage === 1 ? 'bg-gray-300' : ''
                }`}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-2 mx-1 bg-white rounded-[10px] shadow border-1 border-violet-600 hover:text-black text-violet-600 ${currentPage === index + 1 ? 'bg-gray-200' : ''
                  }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              className={` mx-1 w-[72.19px] h-[29.85px] bg-white rounded-[10px] shadow border-1 hover:text-black border-violet-600 text-violet-600 ${currentPage === totalPages ? 'bg-gray-300' : ''
                }`}
            >
              Next
            </button>
          </div>
        </div>


      </div>
    </AuthenticatedLayout>
  );
};

export default StoriesPost;
