import React, { useState } from 'react'
import GuestLayout from '../Layouts/GuestLayout';
import { Link, useNavigate } from 'react-router-dom';
import Socialify from '../images/socialify.png'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import back from '../images/Loginbackimage.png'
import { auth } from "../firebase-config/firebase"
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth'
import { collection,addDoc,setDoc,doc,getFirestore } from 'firebase/firestore';
import { useContext } from 'react';
import  {SettingContext}  from '../Context/SettingContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Signup = () => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [username, setUsername] = useState('');
  const [error, setError] = useState({
    username: '',
    email: '',
    password: '',
    phoneNumber:''
  });
  const [isSignInButtonClicked, setIsSignInButtonClicked] = useState(false);
  const { setUserData } = useContext(SettingContext);

  const googleProvider = new GoogleAuthProvider();
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  // const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[<>]).{8,}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[A-Za-z\s\-']+$/;

  
  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      // Ensure that both name and email are captured correctly
      const authResult = await createUserWithEmailAndPassword(auth, email, password);
      const uid = authResult.user.uid;
      console.log("hello" + uid)
      // setUid(uid);
      const db = getFirestore();
      const collectionRef = collection(db, 'users');
    
      await setDoc(doc(collectionRef,uid), {
        displayName: username,
        email: email,
        userid: uid,
        phoneNumber: phoneNumber,
      });
   
      console.log('User created successfully with username:', username);
      console.log('nameRegex.test(username):', nameRegex.test(username));

      // Use onAuthStateChanged to check if the user is signed in
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // User is signed in, get the ID token
          const idToken = await user.getIdToken();

          // Set the ID token in local storage
          localStorage.setItem('userToken', idToken);

          console.log('User created successfully');
          navigate('/dashboard');
        }

      });
    } catch (error) {
      // Handle the specific error
      if (error.code === 'auth/email-already-in-use') {
        setError({
          email: 'Email address is already in use. Please use a different email or reset your password.',
        });
      } else {
        setError({
          username:  ((username && !nameRegex.test(username)) || (username && username.length < 5)) ? 'Please enter a valid User Name (minimum 5 characters)' : (username ? '' : 'Username cannot be empty.'),
          email: (email && !emailRegex.test(email)) ? 'Invalid email address. Please enter a valid email.' : (email ? '' : 'Email cannot be empty.'),
          password: (password && !strongPasswordRegex.test(password)) ? 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit.' : (password ? '' : 'Password cannot be empty.'),
          confirmPassword: (password !== confirmPassword) ? "password do not match" : "" ,  
          phoneNumber: (phoneNumber === '' && phoneNumber.length !== 11) ? "Cannot be empty" : "Must be at least 11 digit" ,     
   
        });
      }
      console.error('Error creating user:', error);
      setShowAlert(true);

      setTimeout(() => {
        setError({
          ...error,
          username: '',
          email: '',
          password: '',
          phoneNumber:''
        });
      }, 3000);
    }

  };


  const signInWithGoogle = async (e) => {
    e.preventDefault()
    try {
      await signInWithPopup(auth, googleProvider);
      // Use onAuthStateChanged to check if the user is signed in
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        localStorage.setItem('userToken', idToken);

        console.log('User signed in successfully');
        // Navigate to the dashboard after successful sign-in
        navigate('/dashboard');
      }
    });

      setIsSignInButtonClicked(true);
      console.log('User signed in successfully');
    } catch (error) {
      // Handle the specific error
      console.error('Error signing in with Google:', error);
    }
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <GuestLayout>
      
      <div className="grid grid-cols-2 h-full ">

        <div className="lg:col-span-1 col-span-2 bg-purple-400 items-center justify-center hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex w-full h-full">
          <div className="w-full h-full flex justify-center items-center bg-cover bg-center bg-gradient-to-b from-violet-600 to-purple-400" style={{ backgroundImage: `url(${back})` }}>
            <div className="">
              <img className='w-40 h-50' src={Socialify} alt='img' />
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-2 flex justify-center px-5 md:px-0">
          <div className='w-[347px] h-[455px] mx-auto '>
            <h1 className="login text-2xl font-semibold mb-2 font-roboto leading-110 bg-clip-text flex justify-center ">Welcome</h1>

            <p className='text-stone-500 text-sm font-normal flex justify-center  leading-none'>Please Sign Up to continue</p>
            <form action="#" method="POST">
              <div className="mb-3 mt-2">
                <label htmlFor="username" className="text-center text-neutral-700 text-xs font-bold font-Roboto leading-[18px]">Name</label>
                <input onChange={(e) => setUsername(e.target.value)} type="text" id="username" name="username" value={username} className="w-full border border-gray-300 text-stone-500 text-xs font-normal font-'Roboto' leading-[18px] rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500" placeholder='Name' />
                {error.username && <span className="text-red-500 text-xs">{error.username}</span>}

               </div>
              <div className="mb-2 mt-2">
                <label htmlFor="email" className="text-center text-neutral-700 text-xs font-bold font-Roboto leading-[18px]">Email Address</label>
                <input onChange={(e) => {setEmail(e.target.value)}} type="email" id="email" name="email" value={email} className="w-full border border-gray-300 text-stone-500 text-xs font-normal font-'Roboto' leading-[18px] rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500" placeholder='Email' />
                {error.email && <span className="text-red-500 text-xs">{error.email}</span>}

              </div>
              <div className="mb-2 mt-2">
                <label htmlFor="phoneNumber" className="text-center text-neutral-700 text-xs font-bold  leading-[18px]">Phone Number</label>
                <PhoneInput
                  country={'us'}
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                  inputStyle={{
                    width: '100%',
                  }}
                />
                {error.phoneNumber && <span className="text-red-500 text-xs">{error.phoneNumber}</span>}
              </div>
              <div className="mb-2 relative">
                <label htmlFor="password" className="text-center text-neutral-700 text-xs font-bold font-Roboto leading-[18px]">
                  Password
                </label>
                <div className="relative">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    className="w-full text-stone-500 text-xs font-normal font-Roboto leading-[18px] border border-gray-300 rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500"
                    placeholder="Password"
                    autoComplete="off"
                  />
                  
                  <button
                    type="button"
                    className="absolute top-3 right-2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                  {error.password && <span className="text-red-500 text-xs">{error.password}</span>}

                </div>
                <div className="mb-2 mt-2">
                <label htmlFor="confirmPassword" className="text-center text-neutral-700 text-xs font-bold font-Roboto leading-[18px]">Confirm Password</label>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  className="w-full border border-gray-300 text-stone-500 text-xs font-normal font-Roboto leading-[18px] rounded-sm py-2 px-3 focus:outline-none focus:border-blue-500"
                  placeholder='Confirm Password'
                />
                <button
                    type="button"
                    className="absolute right-2 mt-[10px] cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </button>
                {error.confirmPassword && <div className="text-red-500 text-xs">{error.confirmPassword}</div>}

              </div>
             
               
              </div>
             
              <button onClick={handleLogin} type="submit" className="bg-purple-400 hover:bg-purple-500 text-base text-white font-medium rounded-sm py-2 px-4 w-full login-button mb-3">Sign Up</button>
              <p className="flex items-center justify-center text-base font-medium mb-3">Or</p>
              <div className="mb-3 hover:bg-purple-100">
                <button onClick={signInWithGoogle} className="flex flex-wrap text-blue-950 justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-sm">
                  <img className="w-5 mr-2" src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" />
                  Google
                </button>
              </div>
              <hr className='my-2' />
            </form>
            <div className="text-end">
              <span className="text-xs text-gray-400 font-semibold me-1">Already have an Account?</span>
              <Link to={'/login'}>
                <span className='text-xs fw-bold text-purple-700 cursor-pointer'>Sign In</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </GuestLayout>
  )
}

export default Signup