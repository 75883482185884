import React, { createContext, useState } from 'react';

const SettingContext = createContext();

const SettingProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  return (
    <SettingContext.Provider value={{ userData, setUserData }}>
      {children}
    </SettingContext.Provider>
  );
};

export  { SettingContext, SettingProvider };
